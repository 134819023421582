import { HLog } from "src/core/helpers"
// import ModuleV1Page from "src/core/modules/v1page"
// import ModuleDev from "src/core/modules/dev"
import ModuleFirstSetting from "src/core/modules/firstsetting"
import ModuleIconHiOutline from "src/core/modules/iconHiOutline"
import ModuleSetting from "src/core/modules/setting"
import ModuleSetting2FA from "src/core/modules/setting2fa"
import ModuleRedirectToken from "src/core/modules/redirectToken"
import ModuleDevSimulator from "src/core/modules/dev_simulator"
import ModuleDev from "src/core/modules/dev"
import ModuleCore from "src/core/modules/core"
import ModuleReactGA from "src/modules/reactga"
import ModuleAdminPanel from "src/modules/adminpanel"
import ModuleChromeNotification from "src/modules/chrome_notification"
import ModuleSignalR from "src/modules/signalR"
import { HLink, HLocalStorage } from "@macashipo/mlib"
import Lang_en from "../assets/i18n/en.json"
import Lang_vi from "../assets/i18n/vi.json"

import { store } from "src/store/store"
import { logout, updateHeaderTitle } from "src/App/App.actions"
import { fnListConfigApp } from "src/helpers/fnList"
import "src/assets/scss/theme-layout.css"
import { KConfigApp } from "src/helpers/K"
import ModuleAppDemo from "src/modules/app_demo/indes"
const AppConfig: IConfigApp = {
  appInfo: {
    title: "Admin",
    appName: "adminv5",
    home: "/",
    GAId: "UA-119777650-2",
    GooglePlaceApiKey: "AIzaSyCfE6DSnKS3zubPoHaOOgaZqnK-3LNWLPY", //prj DEB DEV
    // GooglePlaceApiKey: "AIzaSyBCngEVc4wGqeENChmkZMXL0I3LWZGhSPY", //prj AllianceITSC
    footer:
      '<span><a href="http://allianceitsc.com">AllianceITSC</a>© 2021 </span>',
    autoRedirectLoginPageNotFound: true,
    verAdmin: "v5",
    defaultTagInputCell: "textarea",
    // serviceWorkersRun: ["/sw-notify.js"],
    // hasSignalR: true,
    // signalRHub: "/NotificationHub",
    // signalRKey: "jkfghweUIT0923klhgfvas65t823fajkwq",
    hasPushNotify: false,
    hasLoginSocialGoogle: false,
    numberLocale: "it-IT", //en-US:,. it-IT,vi-VI:.,
    GoogleClientId:
      "261599714010-770nfjc7ot1s4jh799tone21mmj34cjv.apps.googleusercontent.com",
  },
  configApi: {
    baseUrl: "https://qlpt-api-dev.allianceitsc.com",
    fnLogout: () => {
      // console.warn("logout")
      store.dispatch(logout())
    },
  },
  configI18n: {
    lng: "en",
    defaultNS: "common",
    resources: {
      en: {
        common: Lang_en,
      },
      vi: {
        common: Lang_vi,
      },
    },
  },
  configAuth: {
    afterLogin: () => {
      HLog.logW("afterLogin")
      // HLink.push(fnListConfigApp.getHomeUrl())//dang bi loi, chua biet vi sao
    },
    afterLogout: () => {
      HLocalStorage.removeItem("FirstLoad")
      HLocalStorage.removeItem("SideMenu")
      console.warn("afterLogout")
      // window.location.href = "/"
      // window.open("/", "_self")
      // window.location.reload()
      setTimeout(() => {
        HLink.push("/")
      }, 500) //ko the chay truc tiep, bi loi redux
    },
  },
  fnList: {
    dispatchFromStore(action) {
      store.dispatch(action)
    },
    updateHeaderTitle: title => {
      store.dispatch(updateHeaderTitle(title))
    },
    onAfterInit: function (HConfig) {
      HLog.logW("After init", HConfig)
    },
    fnGetRequestUIAppConfigFirstSetting: () => {
      let _keys = [KConfigApp.expandIconHideColor]
      let _obj = {}
      for (let k of _keys) {
        if (AppConfig.UIAppConfig[k] != null) {
          _obj[k] = AppConfig.UIAppConfig[k]
        }
      }
      return _obj
    },
  },
  modules: [
    ModuleCore,
    ModuleDev,
    ModuleDevSimulator,
    ModuleFirstSetting,
    // ModuleCategoryScreen,
    // ModuleV1Page,
    ModuleIconHiOutline,
    ModuleSetting,
    ModuleSetting2FA,
    ModuleRedirectToken,
    ModuleReactGA,
    ModuleAdminPanel,
    ModuleChromeNotification,
    ModuleSignalR,
    ModuleAppDemo,
  ],
  UIAppConfig: {
    numeralFormat: "0,0.[0]",
    numeralFormatMoney: "0,0.[00]",
    isCheckInvalidProjectId: true,
    ignoreNoProjectId: false,
    timeFormat: "HH:mm",
    dateFormat: "DD-MMMYY", //MomentJS
    datetimeFormat: "DD-MMMYY HH:mm", //MomentJS
    widthMobile: 1000,
    symbolDecimal: ",",
    symbolThousand: ".",
    expandIconHideColor: "",
    expandBgColor: "#e4e5e6", //#93d4e3
  },
}

export default AppConfig
