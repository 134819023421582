export const PATH = {
  HOME: "/",
  PRODUCT: "/product",
  LOGIN: "/login",
  REGISTER: "/register",
  FORGOT: "/forgot",
  RESET: "/reset-password",
  LOAN_NEW: "/loan/new",
  DEMO_OCR: "/demo/orc",
  DEMO_POSTCODE_CHECK: "/demo/postcode-check",
  DEMO_FORM: "/demo/form",
  DEMO_HOME_LOAN: "/demo/homeloan",
  DEMO_HOME_LOANV2: "/demo/homeloan-v2",
  DEMO_PERSONAL_CHAT: "/demo/personalchat",
  DEMO_CREDIT_RULE: "/demo/credit-rule",
}
