import React from "react"
import MyPageHelpers from "src/core/components/MyPage/MyPageHelpers"
import { CSuppense } from "src/core/components"

const PSetting_main = React.lazy(() => import("./Types/setting_main"))

const MyPageOne = {
  init() {
    MyPageHelpers.addTypes("setting_main", props => {
      return CSuppense(props, PSetting_main)
    })
  },
}

export default MyPageOne
