import React from "react"
import { HText, HUtils, MyUI } from "@macashipo/mlib"
import { getData, getMoreProps, getStyle, getTypeMyUI } from "./_helpers"
class MyUI_Data_Tag extends React.Component {
  render() {
    const config = this.props.config || {}
    const data = getData(this.props)
    let _configChild = config.configChild
    // console.error("data_tag", this.props)
    let Tag = config.tag || "div"
    let _moreProps = getMoreProps(this.props)
    let _childIsMyUI = true
    let _childIsText = false
    let _textDisplay = ""
    if (typeof _configChild == "string" || _configChild == null) {
      _childIsMyUI = false
      _childIsText = true
      if (_configChild) {
        if (data && data[_configChild]) {
          _textDisplay = data[_configChild]
        } else {
          _textDisplay = HUtils.getTextWithData(_configChild, data)
        }
      }
    }
    return (
      <Tag
        className={config.className}
        style={getStyle(this.props)}
        {..._moreProps}
      >
        {_childIsMyUI && (
          <MyUI
            {...this.props}
            type={getTypeMyUI(_configChild)}
            data={data}
            config={_configChild}
            {..._configChild.moreProps}
          />
        )}
        {_childIsText && _textDisplay}
      </Tag>
    )
  }
}

export default MyUI_Data_Tag
