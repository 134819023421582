import React from "react"
import MyPageHelpers from "src/core/components/MyPage/MyPageHelpers"
import { CSuppense } from "src/core/components"

const PRedirectToken = React.lazy(() => import("./Types/redirect_token"))

const MyPageOne = {
  init() {
    MyPageHelpers.addTypes("redirect_token", props => {
      return CSuppense(props, PRedirectToken)
    })
  },
}

export default MyPageOne
