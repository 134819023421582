import { fnListMyForm } from "src/helpers/fnList"
export const KMore = {
  disabled: "disabled",
  placeholder: "placeholder",
  typeWrapControl: "typeWrapControl",
}

export const FlowGetConfigFormControlFromV1 = ({
  configFormControl,
  config = {},
} = {}) => {
  //for v5
  if (configFormControl) {
    return configFormControl
  }
  let _propsControl
  if (config.Props) {
    _propsControl = config.Props
  }
  let _more = {}
  if (config.Hint) {
    _more.hint = config.Hint
  }
  if (config.Source || config.SourceField) {
    _more.optionKey = config.Source || config.SourceField
  }
  if (_propsControl) {
    _more = {
      ..._more,
      ..._propsControl,
    }
  }

  let _configFormControl = {
    label: config.Label,
    fieldName: config.FieldName,
    type: config.Type,
    more: _more,
  }
  return _configFormControl
}

export const FlowGetMore = ({ configFormControl = {}, ...rest } = {}) => {
  let _more = {}
  let _configFormControl = FlowGetConfigFormControlFromV1({
    configFormControl,
    ...rest,
  })
  if (_configFormControl && _configFormControl.more) {
    return _configFormControl.more
  }
  return _more
}

export const FlowGetCanEdit = ({ configFormControl, ...rest } = {}) => {
  let _configFormControl = FlowGetConfigFormControlFromV1({
    configFormControl,
    ...rest,
  })
  let _more = FlowGetMore({ configFormControl: _configFormControl, ...rest })
  if (_more[KMore.disabled] == true) {
    return false
  }
  return true
}

export const FlowGetPlaceHolder = ({ configFormControl, ...rest } = {}) => {
  let _configFormControl = FlowGetConfigFormControlFromV1({
    configFormControl,
    ...rest,
  })
  let _more = FlowGetMore({ configFormControl: _configFormControl, ...rest })
  if (_more[KMore.placeholder]) {
    return _more[KMore.placeholder]
  }
  return ""
}

export const FlowGetDefaultValues = ({
  configFormControl,
  fnList,
  ...rest
} = {}) => {
  let _defaultValues = fnListMyForm.getDefaultValues(fnList)
  // console.warn("FlowGetDefaultValues",_defaultValues)
  return _defaultValues
}

export const FlowGetDefaultValueOfFieldName = ({
  configFormControl,
  fieldName,
  defaultValue,
  ...rest
} = {}) => {
  let _configFormControl = FlowGetConfigFormControlFromV1({
    configFormControl,
    ...rest,
  })
  let _fieldName = fieldName || _configFormControl.fieldName
  let _defaultValues = FlowGetDefaultValues({ configFormControl, ...rest })
  if (_defaultValues && _defaultValues.hasOwnProperty(_fieldName)) {
    return _defaultValues[_fieldName]
  }
  return defaultValue != null ? defaultValue : null
}

export const FlowUpdateValueForm = ({
  configFormControl,
  fnList,
  fieldName,
  newValue,
  fnForceUpdateCell,
  ...rest
} = {}) => {
  let _configFormControl = FlowGetConfigFormControlFromV1({
    configFormControl,
    ...rest,
  })
  let _fieldName = fieldName || _configFormControl.fieldName
  console.warn("FlowUpdateValueForm", newValue, fnList)
  if (fnList) {
    //v1,v3
    if (fnList.onUpdateValueOfField) {
      fnList.onUpdateValueOfField({
        fieldName: _fieldName,
        newValue: newValue,
      })
    }
  }
}
