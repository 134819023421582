import { HUtils } from "@macashipo/mlib"
import LodashGet from "lodash/get"
import LodashDebounce from "lodash/debounce"
import { logOne } from "./log"
import HConstant from "./constant"

export const isFunction = (v: any) => {
  return typeof v === "function"
}
export const isArray = (v: any) => {
  return Array.isArray(v)
}
export const isString = (v: any) => {
  return typeof v === "string"
}
export const isDateValue = (v: any) => {
  return new Date(v).getTime() > 0
}
export const mergeObjIfExist = (srcObj, mergeObj) => {
  if (srcObj && mergeObj) {
    let _keys = Object.keys(mergeObj)
    if (_keys && _keys.length > 0) {
      for (let k of _keys) {
        srcObj[k] = mergeObj[k]
      }
    }
  }
}
export const runFuntion = (fnList: any, name, args: any[] = []) => {
  if (fnList && isFunction(fnList[name])) {
    fnList[name].apply(null, args)
  } else {
    logOne("runFuntion failed:", fnList, name)
  }
}
export const safeInvoke = (fn, args: any[] = []) => {
  if (fn) {
    return fn.apply(null, args)
  }
}
export const initMCore = (key, obj) => {
  if (window) {
    if (window["MCore"] == null) {
      window["MCore"] = {}
    }
    window["MCore"][key] = obj
  }
}

export const addArray2Array = (arr, moreArr) => {
  if (arr && moreArr) {
    // arr = arr.concat(moreArr);//ko dung concat duoc, chua  biet tai sao
    arr.push(...moreArr)
  }
}

export const objHasKey = (obj, key) => {
  if (obj && key && obj.hasOwnProperty) {
    return obj.hasOwnProperty(key)
  }
}
// export const combineArray = (array1, array2) => {
//   return [...array1, ...array2]
// }

// export const combineArray = (array1, array2) => {
//   return [].concat(array1, array2)
// }

export const isLocalDev = () => {
  if (window.location.href.indexOf("localhost:") > -1) {
    return true
  }
  if (localStorage.getItem(HConstant.Dev.Key._DEBUG_) === "true") {
    return true
  }
  return false
}

export const onKeyDownEnter = (ev, fn) => {
  if (ev.key === "Enter") {
    fn()
  }
}

export const delay = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export const debounce = (fn, time, opts) => {
  return LodashDebounce(fn, time, opts)
}
export const get = LodashGet

export const getTextWithData = HUtils.getTextWithData

export const checkConditionShow = (value: any, conditionValue) => {
  // console.warn("checkConditionShow:", value, conditionValue)
  let _canShow = true
  if (isArray(conditionValue)) {
    _canShow = false
    for (let i = 0; i < conditionValue.length; i++) {
      let _check = checkConditionShow(value, conditionValue[i])
      if (_check === true) {
        //chi can 1 cai true thi se true
        return true
      }
    }
  } else {
    if (isArray(value)) {
      if (value.indexOf(conditionValue) === -1) {
        return false
      }
    } else if (value !== conditionValue) {
      return false
    }
  }
  return _canShow
}
export const checkConditionShowWithOperator = (
  value: any,
  conditionValue,
  operator,
  format: any
) => {
  console.warn("checkConditionShow:", value, conditionValue)
  let _valueNeedCmp = value
  let _canShow = false
  if (_valueNeedCmp !== null && _valueNeedCmp !== undefined) {
    if (format?.type) {
      switch (format.type) {
        case "year":
          if (
            format?.cmpWith === "now" &&
            new Date().getFullYear() > new Date(_valueNeedCmp).getFullYear()
          ) {
            _valueNeedCmp =
              new Date().getFullYear() - new Date(_valueNeedCmp).getFullYear()
          }
          break

        default:
          break
      }
    }
    switch (operator) {
      case ">=":
        if (_valueNeedCmp >= conditionValue) {
          _canShow = true
        }
        break
      case ">":
        if (_valueNeedCmp > conditionValue) {
          _canShow = true
        }
        break
      case "!=":
        if (_valueNeedCmp !== conditionValue) {
          _canShow = true
        }
        break
      case "=":
        if (_valueNeedCmp === conditionValue) {
          _canShow = true
        }
        break
      case "<":
        if (_valueNeedCmp < conditionValue) {
          _canShow = true
        }
        break
      case "<=":
        if (_valueNeedCmp <= conditionValue) {
          _canShow = true
        }
        break
      default:
        break
    }
  }
  return _canShow
}

export const xorCrypt = function (str: string = "", key: number = 7) {
  var output = ""
  if (!key) {
    key = 6
  }
  for (var i = 0; i < str.length; ++i) {
    output += String.fromCharCode(key ^ str.charCodeAt(i))
  }
  return output
}

window["_xorCrypt"] = xorCrypt
