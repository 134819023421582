import { openUrl } from "./link"
import { fnListUI } from "src/helpers/fnList"
export const parseResponseButtonApi = function (
  res: any,
  { target = "_blank", autoOpenLink = false } = {}
) {
  if (res && res.Data) {
    let _data = res.Data
    if (_data.FullPath) {
      if (autoOpenLink === true) {
        openUrl(_data.FullPath, target)
      } else {
        fnListUI.fnshowDialog({
          title: _data.FullPathTitle || "Link",
          msg: (
            <div style={{ wordWrap: "break-word" }}>
              <div>{_data.FullPathInfo}</div>
              <a
                href={_data.FullPath}
                target={target}
                download={_data.FullPathDownload === true ? true : false}
              >
                {_data.FullPath}
              </a>
            </div>
          ),
        })
      }
    }
  }
}
