import React, { useState } from "react"
import { connect, ConnectedProps } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import { Button, Input, InputGroup, InputGroupText } from "reactstrap"
import { MyIcon, MyUI } from "src/core/components"
import { HAuth } from "src/core/helpers"
import { PATH } from "src/helpers/paths"
import { showToastError } from "src/core/helpers/ui"
import ApiGeneric from "src/services/apiGeneric"
// import {
//   HiOutlineMail,
//   HiOutlineKey,
//   HiOutlineEye,
//   HiOutlineEyeOff,
// } from "react-icons/hi"
// import IMGlogo from "src/assets/images/logo.png"
import {
  ErrorMessage,
  // Input,
  Form,
  Title,
} from "./Login.styles"
import { login, loginSuccessWithResponse } from "./Login.thunks"
import Container from "./LoginContainer"

const mapStateToProps = state => ({
  loading: state.loading,
  isAuthenticated: state.app.isAuthenticated,
})

const mapDispatchToProps = {
  login,
  loginSuccessWithResponse,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

interface Props extends ConnectedProps<typeof connector> {}

const Login = (props: Props) => {
  const { loading, loginSuccessWithResponse, isAuthenticated } = props
  const fa2_needenable = HAuth.getInfo2FA()["fa2_needenable"]
  const fa2_needverify = HAuth.getInfo2FA()["fa2_needverify"]
  const history = useHistory()
  if (isAuthenticated) {
    history.push(PATH.HOME)
  }
  const [username, setUsername] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [name, setName] = useState("")
  const [password, setPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const [cfpassword, setCFPassword] = useState("")
  const [showCFPassword, setShowCFPassword] = useState(false)
  const [error, setError] = useState("")
  const [loadingRegister, setLoadingRegister] = useState(false)

  const requestRegister = (data = {}) => {
    setLoadingRegister(true)
    ApiGeneric({
      method: "POST",
      url: "Account/Register",
      data: data,
    })
      .then(r => {
        setLoadingRegister(false)
        console.warn("r:", r)
        if (r && r.Data) {
          loginSuccessWithResponse(r.Data)
          history.push(PATH.HOME)
        }
      })
      .catch(err => {
        setLoadingRegister(false)
        if (err && err.msg) {
          setError(err.msg)
        }
      })
  }

  const checkConfirmPassword = () => {
    if (password.length === 0) {
      showToastError("Please fill your password")
      return false
    } else if (password.length > 0 && password !== cfpassword) {
      showToastError("Password and Confirm Password must be match")
      return false
    }
    return true
  }

  const submit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (!loading && checkConfirmPassword()) {
      const payload = {
        UserName: username,
        DisplayName: name,
        Email: email,
        PhoneNumber: phone,
        Password: password,
        ConfirmPassword: cfpassword,
      }
      requestRegister(payload)
    }
  }
  const onShowPassword = value => {
    setShowPassword(value)
  }
  const onShowCFPassword = value => {
    setShowCFPassword(value)
  }

  return (
    <Container loading={loadingRegister}>
      {fa2_needenable === true || fa2_needverify === true ? (
        <Form
          className="p-5 rounded-sm shadow text-center"
          onSubmit={e => {
            e.preventDefault()
          }}
        >
          <MyUI
            type="setting2fa_selectmethod2fa"
            fnList={{
              dispatchLoginSuccess: res => {
                loginSuccessWithResponse(res)
              },
            }}
          />
        </Form>
      ) : (
        <Form className="p-5 rounded-sm shadow text-center" onSubmit={submit}>
          <Title style={{ marginTop: "0px" }}>Register</Title>
          <InputGroup style={{ marginBottom: "1rem" }}>
            <InputGroupText>
              <MyIcon data="type:HiOutlineUser" />
            </InputGroupText>
            <Input
              placeholder={"Username"}
              name="username"
              disabled={loadingRegister}
              autoComplete="on"
              value={username}
              autoFocus
              onChange={ev => {
                setUsername(ev.currentTarget.value)
              }}
            />
          </InputGroup>
          <InputGroup style={{ marginBottom: "1rem" }}>
            <InputGroupText>
              <MyIcon data="type:HiOutlineUser" />
            </InputGroupText>
            <Input
              placeholder={"Display Name"}
              name="displayname"
              disabled={loadingRegister}
              autoComplete="on"
              value={name}
              onChange={ev => {
                setName(ev.currentTarget.value)
              }}
            />
          </InputGroup>
          <InputGroup style={{ marginBottom: "1rem" }}>
            <InputGroupText>
              <MyIcon data="type:HiOutlineMail" />
            </InputGroupText>
            <Input
              placeholder={"Email"}
              name="email"
              disabled={loadingRegister}
              autoComplete="on"
              value={email}
              onChange={ev => {
                setEmail(ev.currentTarget.value)
              }}
            />
          </InputGroup>
          <InputGroup style={{ marginBottom: "1rem" }}>
            <InputGroupText>
              <MyIcon data="type:HiOutlinePhone" />
            </InputGroupText>
            <Input
              placeholder={"Phone number"}
              name="phonenumber"
              disabled={loadingRegister}
              autoComplete="on"
              value={phone}
              onChange={ev => {
                setPhone(ev.currentTarget.value)
              }}
            />
          </InputGroup>
          <InputGroup style={{ marginBottom: "1rem" }}>
            <InputGroupText>
              <MyIcon data="type:HiOutlineKey" />
            </InputGroupText>
            <Input
              type={showPassword === true ? "text" : "password"}
              placeholder={"Password"}
              name="password"
              disabled={loadingRegister}
              autoComplete="on"
              value={password}
              onChange={ev => {
                setPassword(ev.currentTarget.value)
              }}
            />
            <InputGroupText
              onClick={() => {
                onShowPassword(!showPassword)
              }}
            >
              <MyIcon
                data={
                  showPassword ? "type:HiOutlineEye" : "type:HiOutlineEyeOff"
                }
              />
            </InputGroupText>
          </InputGroup>
          <InputGroup style={{ marginBottom: "1rem" }}>
            <InputGroupText>
              <MyIcon data="type:HiOutlineKey" />
            </InputGroupText>
            <Input
              type={showCFPassword === true ? "text" : "password"}
              placeholder={"Confirm Password"}
              disabled={loadingRegister}
              value={cfpassword}
              onChange={ev => {
                setCFPassword(ev.currentTarget.value)
              }}
            />
            <InputGroupText
              onClick={() => {
                onShowCFPassword(!showCFPassword)
              }}
            >
              <MyIcon
                data={
                  showCFPassword ? "type:HiOutlineEye" : "type:HiOutlineEyeOff"
                }
              />
            </InputGroupText>
          </InputGroup>
          {error && (
            <ErrorMessage className="mb-3 text-danger text-xl-center">
              {error}
            </ErrorMessage>
          )}
          <Button
            type="submit"
            color="primary"
            style={{ width: "100%" }}
            disabled={loadingRegister}
          >
            Register
          </Button>
          <div
            style={{
              marginTop: "0.5rem",
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <Link to={PATH.LOGIN}>Back to Sign in</Link>
          </div>
        </Form>
      )}
    </Container>
  )
}

export default connector(Login)
