import { HIs, HLink } from "@macashipo/mlib"
import * as HJson from "./json"
import * as HUtils from "./utils"
import * as HLocalStorage from "./localStorage"
import * as HLog from "./log"
import * as HApi from "./api"
import * as HAuth from "./auth"
import * as HUI from "./ui"
import * as HRegex from "./regex"
// import * as HLink from "./link"
import * as HQuery from "./query"
import * as HOptions from "./options"
import HConfig from "./config"
import HConstant from "./constant"
import HServiceWorker from "./serviceWorker"
import HFormat from "./format"
import HC from "./c"
export {
  HIs,
  HC,
  HLink,
  HJson,
  HUtils,
  HLocalStorage,
  HLog,
  HConfig,
  HApi,
  HAuth,
  HConstant,
  HUI,
  HRegex,
  HServiceWorker,
  HQuery,
  HOptions,
  HFormat,
}

HUtils.initMCore("HJson", HJson)
HUtils.initMCore("HUtils", HUtils)
HUtils.initMCore("HUI", HUI)
// HUtils.initMCore("HApi", HApi)
// HUtils.initMCore("HAuth", HAuth)
