import React from "react"
import { MyTableFilterControlHelpers, HFormat } from "@macashipo/mlib"

const MTFCSelect2 = React.lazy(() => import("./Types/select2"))
const MTFCSelect2Content = React.lazy(() => import("./Types/select2content"))
const MTFCSwitch = React.lazy(() => import("./Types/switch"))
const MTFCCheckbox = React.lazy(() => import("./Types/checkbox"))
const MTFCDateFromTo = React.lazy(() => import("./Types/datefromto"))
const MTFCDateFromToText = React.lazy(() => import("./Types/datefromto_text"))
const MTFCText = React.lazy(() => import("./Types/text"))

const List = {
  datefromto: MTFCDateFromTo,
  datefromto_text: MTFCDateFromToText,
  checkbox: MTFCCheckbox,
  select2: MTFCSelect2,
  select2content: MTFCSelect2Content,
  switch: MTFCSwitch,
  text: MTFCText,
}
const MyTableFilterControlOne = {
  init() {
    MyTableFilterControlHelpers.addTypesFromListLazy(List)
    if (MyTableFilterControlHelpers.ConfigByType == null) {
      MyTableFilterControlHelpers.ConfigByType = {}
    }
    if (MyTableFilterControlHelpers.ConfigByType) {
      MyTableFilterControlHelpers.ConfigByType["_default"] = {
        getFilterOut: (value, oneFilter) => {
          let _isServer = false
          if (oneFilter.more) {
            if (oneFilter.more.isServer === true) {
              _isServer = true
            }
          }
          return {
            fieldName: oneFilter.fieldName,
            isServer: _isServer,
            more: oneFilter.more,
            title: oneFilter.title,
            value: value,
            valueDisplay: value,
          }
        },
      }
      MyTableFilterControlHelpers.ConfigByType["select2"] = {
        getFilterOut: (value, oneFilter) => {
          console.warn("getFilterOut:", value, oneFilter)
          return {
            ...MyTableFilterControlHelpers.ConfigByType[
              "_default"
            ].getFilterOut(value, oneFilter),
            value: {
              Text: value.Text,
              Value: value.Value,
            },
            valueDisplay: value.Text,
            valueFilterServer: value.Value,
            valueFilterClient: value.Value,
          }
        },
      }
      MyTableFilterControlHelpers.ConfigByType["select2content"] = {
        getFilterOut: (value, oneFilter) => {
          console.warn("getFilterOut4554:", value, oneFilter)
          return {
            ...MyTableFilterControlHelpers.ConfigByType[
              "_default"
            ].getFilterOut(value, oneFilter),
            value: {
              Text: value.Text,
              Value: value.Value,
            },
            type: value.Filter.type,
            valueDisplay: value.Text,
            valueFilterServer: value.Filter.value,
            valueFilterClient: value.Filter.value,
          }
        },
      }
      MyTableFilterControlHelpers.ConfigByType["switch"] = {
        getFilterOut: (value, oneFilter) => {
          console.warn("getFilterOut:", value, oneFilter)
          return {
            ...MyTableFilterControlHelpers.ConfigByType[
              "_default"
            ].getFilterOut(value, oneFilter),
            value: value,
            valueDisplay: oneFilter.title,
          }
        },
      }
      MyTableFilterControlHelpers.ConfigByType["checkbox"] = {
        getFilterOut: (value, oneFilter) => {
          console.warn("getFilterOut:", value, oneFilter)
          return {
            ...MyTableFilterControlHelpers.ConfigByType[
              "_default"
            ].getFilterOut(value, oneFilter),
            value: value,
            valueDisplay: `${oneFilter.title}: ${
              value === true ? "Yes" : "No"
            }`,
          }
        },
      }
      MyTableFilterControlHelpers.ConfigByType["datefromto"] = {
        getFilterOut: (value, oneFilter) => {
          console.warn("getFilterOut:", value, oneFilter)
          let _testDisplay = ""
          let _valueFilterServer = {}
          if (value && value.length > 1) {
            let _from = HFormat.format(value[0], {
              type: "date",
              format: "DD/MM/YYYY",
            })
            let _to = HFormat.format(value[1], {
              type: "date",
              format: "DD/MM/YYYY",
            })
            _valueFilterServer = {
              start: new Date(value[0]).getTime(),
              end: new Date(value[1]).getTime(),
            }
            _testDisplay = `${_from} - ${_to}`
          }
          return {
            ...MyTableFilterControlHelpers.ConfigByType[
              "_default"
            ].getFilterOut(value, oneFilter),
            value: value,
            valueDisplay: _testDisplay,
            valueFilterServer: _valueFilterServer,
          }
        },
      }
      MyTableFilterControlHelpers.ConfigByType["datefromto_text"] = {
        getFilterOut: (value, oneFilter) => {
          console.warn("getFilterOut:", value, oneFilter)
          let _testDisplay = ""
          let _valueFilterServer = {}
          if (value && value.length > 1) {
            let _from = HFormat.format(value[0], {
              type: "date",
              format: "DD/MM/YYYY",
            })
            let _to = HFormat.format(value[1], {
              type: "date",
              format: "DD/MM/YYYY",
            })
            _valueFilterServer = {
              start: HFormat.format(new Date(value[0]).getTime(), {
                type: "date",
                format: "YYYY-MM-DD",
              }),
              end: HFormat.format(new Date(value[1]).getTime(), {
                type: "date",
                format: "YYYY-MM-DD",
              }),
            }
            _testDisplay = `${_from} - ${_to}`
          }
          return {
            ...MyTableFilterControlHelpers.ConfigByType[
              "_default"
            ].getFilterOut(value, oneFilter),
            value: value,
            valueDisplay: _testDisplay,
            valueFilterServer: _valueFilterServer,
          }
        },
      }
    }
  },
}

export default MyTableFilterControlOne
