import { toast } from "react-toastify"
import { MyDialog } from "src/core/components"
import { HIs, HCF } from "@macashipo/mlib"
import { KUI } from "src/helpers/K"
const configToast = {
  hideProgressBar: true,
  pauseOnHover: true,
  autoClose: 2000,
}

const ToastType = {
  success: "success",
  warn: "warn",
  warning: "warning",
  info: "info",
  error: "error",
}

export const showToast = (msg, options: any = {}) => {
  let _options = { ...configToast, ...options }
  let _type = options.type || ToastType.success
  //check first msg
  if (HIs.isString(msg)) {
    if (msg.startsWith("[Warning]")) {
      msg = msg.replace("[Warning]", "")
      _type = ToastType.warning
    } else if (msg.startsWith("[Error]")) {
      msg = msg.replace("[Error]", "")
      _type = ToastType.error
    }
  }
  switch (_type) {
    case ToastType.success:
      toast.success(msg, _options)
      break
    case ToastType.warn:
      toast.warn(msg, _options)
      break
    case ToastType.warning:
      toast.warn(msg, _options)
      break
    case ToastType.info:
      toast.info(msg, _options)
      break
    case ToastType.error:
      toast.error(msg, _options)
      break
  }
}

export const showToastWithCheckMsg = (msg, defaultType = ToastType.success) => {
  if (msg) {
    if (msg.startsWith("[")) {
      if (msg.startsWith("[Warning]")) {
        showToast(msg.replace("[Warning]", ""), {
          type: ToastType.warning,
        })
      } else if (msg.startsWith("[Error]")) {
        showToast(msg.replace("[Error]", ""), {
          type: ToastType.error,
        })
      } else if (msg.startsWith("[Info]")) {
        showToast(msg.replace("[Info]", ""), {
          type: ToastType.info,
        })
      } else if (msg.startsWith("[Success]")) {
        showToast(msg.replace("[Success]", ""), {
          type: ToastType.success,
        })
      } else if (msg.startsWith("[Alert]")) {
        showDialog({
          title: HCF.getCF("title") || "Notice",
          msg: msg.replace("[Alert]", ""),
        })
      } else if (msg.startsWith("[System]") && window.alert) {
        window.alert(msg.replace("[System]", ""))
      } else {
        showToast(msg, {
          type: defaultType,
        })
      }
    } else {
      showToast(msg, {
        type: defaultType,
      })
    }
  }
}

export const showToastSuccess = msg => {
  showToastWithCheckMsg(msg, ToastType.success)
}

export const showToastWarning = msg => {
  showToastWithCheckMsg(msg, ToastType.warning)
}

export const showToastError = msg => {
  showToastWithCheckMsg(msg, ToastType.error)
}

export const showToastLikeToast = msg => {
  if (msg) {
    showToast(msg, {
      type: ToastType.success,
      position: toast.POSITION.BOTTOM_CENTER,
      style: {
        background: "black",
        color: "white",
      },
    })
  }
}

export const showApiMsg = msg => {
  if (msg) {
    showToastSuccess(msg)
  }
}

export const hideInMyModalFromProps = props => {
  if (props && props.inMyModal && props.inMyModal.hide) {
    props.inMyModal.hide()
  }
}

export const showDialog = (configDialog: any) => {
  console.warn("showDialog", configDialog)
  MyDialog.show({
    ...configDialog,
  })
}

export const showConfirm = (configDialog: any) => {
  console.warn("showConfirm", configDialog)
  MyDialog.showConfirm({
    ...configDialog,
  })
}

HCF.setCF(KUI.fnShowToast, showToast)
HCF.setCF(KUI.fnshowDialog, showDialog)
HCF.setCF(KUI.fnshowConfirm, showConfirm)
window["showToastWithCheckMsg"] = showToastWithCheckMsg
