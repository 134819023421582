import React from "react"
import { MyTableCellHelpers, HUtils, HJson, HCF } from "@macashipo/mlib"
import { KMore } from "src/helpers/Flows/TableCell"
import { KConfigApp } from "src/helpers/K"

const MTCReadonly = React.lazy(() => import("./Types/readonly"))
// const MTCText = React.lazy(() => import("./Types/text"))
const MTCTextarea = React.lazy(() => import("./Types/textarea"))
const MTCApi = React.lazy(() => import("./Types/api"))
const MTCJsonEditor = React.lazy(() => import("./Types/json_editor"))
const MTCCheckbox = React.lazy(() => import("./Types/checkbox"))
const MTCExpandMulti = React.lazy(() => import("./Types/expand_multi"))
// const MTCSelect = React.lazy(() => import("./Types/select"))
const MTCSelect2Modal = React.lazy(() => import("./Types/select2_modal"))
const MTCSelectModern = React.lazy(() => import("./Types/select_modern"))
const MTCDelete = React.lazy(() => import("./Types/delete"))
const MTCTextareafull = React.lazy(() => import("./Types/textareafull"))
const MTCLink = React.lazy(() => import("./Types/link"))
const MTCNotexist = React.lazy(() => import("./Types/notexist"))
const MTCFiles = React.lazy(() => import("./Types/files"))
const MTCDate = React.lazy(() => import("./Types/date"))
const MTCDateText = React.lazy(() => import("./Types/date_text"))
const MTCArrayEditableModal = React.lazy(() =>
  import("./Types/array_editable_modal")
)
const MTCActionsMenu = React.lazy(() => import("./Types/actions_menu"))
const MTCForm = React.lazy(() => import("./Types/form"))
const MTCReadOnlyArray = React.lazy(() => import("./Types/readonly_array"))
const MTCurrency = React.lazy(() => import("./Types/currency"))
const MTCNumberDecimal = React.lazy(() => import("./Types/number_decimal"))
const MTCNumber = React.lazy(() => import("./Types/number"))
const MTCListText = React.lazy(() => import("./Types/list_text"))
const MTCWithMyUI = React.lazy(() => import("./Types/with_myui"))
const MTCActions = React.lazy(() => import("./Types/actions"))
const MTCCopy = React.lazy(() => import("./Types/copy"))
const MTC_readonly_date = React.lazy(() => import("./Types/readonly_date"))
const MTC_pickcolor = React.lazy(() => import("./Types/pickcolor"))
const MTC_chromecolor = React.lazy(() => import("./Types/chromecolor"))
const MTC_readonly_html = React.lazy(() => import("./Types/readonly_html"))
const MTCJsonEditorValue = React.lazy(() => import("./Types/json_editor_value"))
// const MTCText = React.lazy(() => import("./Types/text"))
const MTCSuperMan = React.lazy(() => import("./Types/superman"))
const MTCReadonlyWithTree = React.lazy(() =>
  import("./Types/readonly_with_tree")
)
const MTC_modal_table_extra = React.lazy(() =>
  import("./Types/modal_table_extra")
)
const MTC_quality = React.lazy(() => import("./Types/quality"))
const MTC_readonly_status = React.lazy(() => import("./Types/readonly_status"))
const MTC__barTopRight = React.lazy(() => import("./Types/_barTopRight"))
const MTCSelect2ModalTable = React.lazy(() =>
  import("./Types/select2_modal_table")
)
const MTC__SelectOnModal = React.lazy(() => import("./Types/_select_on_modal"))
const MTC_html_editor = React.lazy(() => import("./Types/html_editor"))
const MTCFormModalTable = React.lazy(() => import("./Types/form_modal_table"))
const MTCDevTest = React.lazy(() => import("./Types/dev_test"))
const MTCSelect2 = React.lazy(() => import("./Types/select2"))
const MTC_cs_email_tp_action = React.lazy(() =>
  import("./Types/cs_email_tp_action")
)
const MTC_readonly_datetime = React.lazy(() =>
  import("./Types/readonly_datetime")
)
const List = {
  _select_on_modal: MTC__SelectOnModal,
  _barTopRight: MTC__barTopRight,
  cs_email_tp_action: MTC_cs_email_tp_action,
  dev_test: MTCDevTest,
  form_modal_table: MTCFormModalTable,
  html_editor: MTC_html_editor,
  select2_modal_table: MTCSelect2ModalTable,
  readonly_status: MTC_readonly_status,
  quality: MTC_quality,
  modal_table_extra: MTC_modal_table_extra,
  readonly_with_tree: MTCReadonlyWithTree,
  // text: MTCText,
  superman: MTCSuperMan,
  readonly_html: MTC_readonly_html,
  chromecolor: MTC_chromecolor,
  pickcolor: MTC_pickcolor,
  readonly_date: MTC_readonly_date,
  with_myui: MTCWithMyUI,
  list_text: MTCListText,
  number: MTCNumber,
  number_decimal: MTCNumberDecimal,
  input_format_numeral_money: MTCurrency,
  currency: MTCurrency,
  readonly_array: MTCReadOnlyArray,
  form: MTCForm,
  actions: MTCActions,
  action: MTCActions,
  actions_menu: MTCActionsMenu,
  array_editable_modal: MTCArrayEditableModal,
  date: MTCDate,
  datetime: MTCDate,
  date_text: MTCDateText,
  link: MTCLink,
  readonly: MTCReadonly,
  text: MTCTextarea, //xai chung voi textarea
  select: MTCSelectModern,
  select2: MTCSelect2,
  // select2_addable: MTCSelectModern,
  select2_modal: MTCSelect2Modal,
  select_modern: MTCSelectModern,
  textarea: MTCTextarea,
  textareafull: MTCTextareafull,
  api: MTCApi,
  json_editor: MTCJsonEditor,
  json_editor_value: MTCJsonEditorValue,
  checkbox: MTCCheckbox,
  expand_multi: MTCExpandMulti,
  expand_api: MTCExpandMulti,
  delete: MTCDelete,
  notexist: MTCNotexist,
  files: MTCFiles,
  readonly_datetime: MTC_readonly_datetime,
  // copy: MTCCopy,
}
const MyTableCellOne = {
  init() {
    MyTableCellHelpers.addTypesFromListLazy(List)
    MyTableCellHelpers.Types["_auto"] = (cell, row, extra, index) => {
      let _type = HUtils.get(extra, "Type.type")
      let _more = HUtils.get(extra, "Type.more")
      // console.log("_more:", _more)
      if (
        _more &&
        _more.indexOf(KMore.typeAdmin) > -1 &&
        HCF.getCF(KConfigApp.verAdmin) == "v5"
      ) {
        let _moreObj = HJson.getObj(_more)
        if (_moreObj && _moreObj[KMore.typeAdmin]) {
          _type = _moreObj[KMore.typeAdmin]
        }
      }
      if (MyTableCellHelpers.Types[_type]) {
        let TagCell = MyTableCellHelpers.Types[_type]
        //phai tra ve dang tag moi de nhan dien trong TableColumn
        return <TagCell cell={cell} row={row} extra={extra} index={index} />
      } else {
        if (MyTableCellHelpers.Types.notexist) {
          return MyTableCellHelpers.Types.notexist({ cell, row, extra, index })
        }
        return MyTableCellHelpers.Types._empty({ cell, row, extra, index })
      }
    }
    MyTableCellHelpers["ConfigByTypes"] = {
      api: {
        isHide: oneTableCell => {
          if (oneTableCell) {
            let _valueCell = oneTableCell.getValue()
            // console.log("check is Hide")
            if (_valueCell === "" || _valueCell === null) return true
          }
        },
      },
      datetime: {
        moreAttrs: {
          moreOptions: {
            enableTime: true,
            dateFormat: "d/m/Y H:i",
          },
        },
      },
    }
  },
}

export default MyTableCellOne
