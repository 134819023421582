import {
  HCF,
  HJson,
  HUtils,
  MyTableCellHelpers,
  MyTableHeader,
  MyTableHeaderHelpers,
} from "@macashipo/mlib"
import {
  fnListMyPage,
  fnListConfigApp,
  fnGetConfigTable,
  kfnGetColumnSelect,
} from "./fnList"
import { KConfigPageTable } from "./K"
import * as FlowTableExpand from "./Flows/TableExpand"
import * as FlowTableExtraData from "./Flows/TableExtraData"
import * as FlowTableCell from "./Flows/TableCell"
import * as FlowTableFilter from "./Flows/TableFilter"
import * as FlowAuth from "./Flows/Auth"
import * as FlowRoutes from "./Flows/Routes"
import * as FlowVer from "./FlowV3"
import * as FlowPage from "./Flows/Page"
import * as FlowApi from "./Flows/Api"
import * as FlowMNeedUpdateMLib from "./Flows/MNeedUpdateMLib"
import * as FlowFormControl from "./Flows/FormControl"
const {
  DefaultWidthID,
  DefaultWidthCol,
  DefaultWidthColExpand,
  DefaultWidthColDelete,
} = FlowVer

// fnList bat buoc:
// - fnGetConfigPageTable
// - fnGetConfigPageDetail

export {
  FlowAuth,
  FlowTableExpand,
  FlowTableExtraData,
  FlowTableCell,
  FlowTableFilter,
  FlowRoutes,
  FlowPage,
  FlowApi,
  FlowMNeedUpdateMLib,
  FlowFormControl,
}
export const FlowBuildColTable_BuildExtraColumns = ({
  extraData,
  fnList,
  sourceList,
}) => {
  let _addExtraColumns =
    fnListMyPage.fnGetConfigPageTable(fnList, [
      KConfigPageTable.addExtraColumns,
    ]) || {}
  //column select modal
  if (fnList && fnList[kfnGetColumnSelect]) {
    let _configColumnSelect = fnList[kfnGetColumnSelect]()
    if (_configColumnSelect) {
      _addExtraColumns["_ColSelectOnModal"] = _configColumnSelect
    }
  }
  //column expand
  let _showDetails = fnListMyPage.fnGetConfigPageTable(fnList, [
    KConfigPageTable.showDetails,
  ])
  let _showDetailsMulti = fnListMyPage.fnGetConfigPageTable(fnList, [
    KConfigPageTable.showDetailsMulti,
  ])
  if (_showDetails || _showDetailsMulti) {
    let _detailConfig = fnListMyPage.fnGetConfigPageDetail(fnList, [])
    if (_detailConfig) {
      try {
        _detailConfig = JSON.stringify(_detailConfig)
        _addExtraColumns["_ColExpandMulti"] = {
          Type: { type: "expand_multi", more: _detailConfig },
          Index: 0,
          CanShow: true,
          CanEdit: false,
          CanSort: false,
          Header: " ",
          Width: DefaultWidthColExpand,
        }
      } catch (error) {
        console.warn("Error JSON", error, _detailConfig)
      }
    }
  }
  //column action
  //column action menu
  let _configColsActions = fnGetConfigTable(
    fnList,
    KConfigPageTable.colsActions
  )
  if (_configColsActions) {
    if (_configColsActions.show === true) {
      _addExtraColumns["_colActions"] = {
        Type: {
          type: "actions_menu",
          more: HJson.getString(_configColsActions),
        },
        CanShow: true,
        CanEdit: false,
        CanSort: false,
        Header: " ",
        Width: DefaultWidthColExpand,
      }
    }
  }
  //column delete
  if (
    fnListMyPage.fnGetConfigPageTable(fnList, [KConfigPageTable.canDelete]) !==
    false
  ) {
    let _isHideColDelete = false
    if (
      fnListMyPage.fnGetConfigPageTable(fnList, [
        KConfigPageTable.hideColDelete,
      ]) === true
    ) {
      _isHideColDelete = true
    }
    if (
      fnListMyPage.fnGetConfigPageTable(fnList, [
        KConfigPageTable.hideColDeleteOnSmallWidth,
      ]) === true &&
      fnListConfigApp.getIsMobileByWidth()
    ) {
      _isHideColDelete = true
    }
    if (_isHideColDelete !== true) {
      let _typeCell = { type: "delete" }
      let _customDelete =
        fnListMyPage.fnGetConfigPageTable(fnList, [
          KConfigPageTable.customDelete,
        ]) || {}
      let _width = HUtils.get(_customDelete, "width") || DefaultWidthColDelete
      if (_customDelete) {
        let _type = HUtils.get(_customDelete, "type")
        let _more = HUtils.get(_customDelete, "more")
        if (_type) {
          _typeCell.type = _type
        }
        if (_more) {
          _typeCell.more = _more
        }
      }
      _addExtraColumns["_ActionDelete"] = {
        Type: _typeCell,
        Index: 1000,
        CanShow: true,
        CanEdit: true,
        CanSort: false,
        Header: " ",
        CanPrint: false,
        Width: _width,
      }
    }
  }
  //return
  return _addExtraColumns
}
export const FlowBuildColTable_BuildOneCol_ParseCustomProps = ({
  colExtra,
  colConfig = {},
  fieldName,
  customProps,
  customType,
} = {}) => {
  let _fieldName = fieldName
  //
  if (customProps != null && customProps["_Arr"] != null) {
    let _regex = customProps["_Arr"].regex
    let _test = _regex.test(_fieldName)
    if (_test == true) {
      colConfig.colProps = Object.assign(
        colConfig.colProps,
        customProps["_Arr"].props
      )
    }
  }
  if (customProps != null && customProps[_fieldName] != null) {
    colConfig.colProps = Object.assign(
      colConfig.colProps,
      customProps[_fieldName]
    )
  }
  if (customType != null && customType[_fieldName] != null) {
    colExtra.Type = Object.assign(colExtra.Type, customType[_fieldName])
  }
}
export const FlowBuildColTable_BuildOneCol_ParseColWidth = ({
  colExtra,
  colConfig = {},
} = {}) => {
  let _item = colExtra
  //
  if (_item.MWAS && _item.MWASD) {
    let _w = window.innerWidth > _item.MWAS ? "100%" : _item.MWASD + "px"
    colConfig.width = _w
  }
}
export const FlowBuildColTable_BuildOneCol_ParseHeader = ({
  colExtra,
  colConfig = {},
  extraData,
  fnList,
} = {}) => {
  let _item = colExtra
  //
  if (_item.CanSort != null) {
    colConfig.colProps.dataSort = _item.CanSort
  }
  if (_item.CanPrint === false) {
    colConfig.columnClassName =
      colConfig.columnClassName || "" + " no-print-col"
    colConfig.className = colConfig.className || "" + " no-print-col"
  }
  if (_item.Cls != null) {
    colConfig.className = colConfig.className || "" + " " + _item.Cls
  }
  if (_item.CCls != null) {
    colConfig.columnClassName =
      colConfig.columnClassName || "" + " " + _item.CCls
  }
  //parse json header
  if (_item.Header && _item.Header.startsWith("{")) {
    try {
      _item._HeaderObj = JSON.parse(_item.Header)
      let _headerObj = _item._HeaderObj
      if (_headerObj) {
        // console.warn("render header:", _headerObj)
        colConfig.header = _headerObj.title || _headerObj.header || ""
        colConfig.colProps.headerText = colConfig.header //bo sung title cho column
        if (_headerObj.type) {
          // colConfig.header = (
          //   <div style={{ display: "inline", ..._headerObj.style }}>
          //     {colConfig.header}
          //   </div>
          // )
          if (_headerObj.type && MyTableHeaderHelpers.Types[_headerObj.type]) {
            colConfig.colProps.componentHeader = (header, fieldName) => {
              // console.warn("style header:", header, fieldName, _headerObj)
              return (
                <MyTableHeader
                  type={_headerObj.type}
                  header={header}
                  fieldName={fieldName}
                  headerObj={_headerObj}
                  extraData={extraData}
                  fnList={fnList}
                />
              )
            }
          }
        }
        if (_headerObj.hideSort) {
          colConfig.colProps.caretRender = (sort, dataField) => {
            return <div></div>
          }
        }
        if (
          _headerObj.canSort != null &&
          typeof _headerObj.canSort == "boolean"
        ) {
          colConfig.colProps.dataSort = _headerObj.canSort
        }
        if (_headerObj.canPrint === false) {
          colConfig.columnClassName =
            colConfig.columnClassName || "" + " no-print-col"
          colConfig.className = colConfig.className || "" + " no-print-col"
        }
        if (_headerObj.Cls != null) {
          colConfig.className = colConfig.className || "" + " " + _headerObj.Cls
        }
        if (_headerObj.CCls != null) {
          colConfig.columnClassName =
            colConfig.columnClassName || "" + " " + _headerObj.CCls
        }
        if (_headerObj.hidden) {
          colConfig.hidden = true
        }
      }
    } catch (error) {
      console.warn("error JSON", error, _item, _item.Header)
    }
  }
}
export const FlowBuildColTable_BuildOneCol = ({
  extraData,
  colExtra,
  fieldName,
  fnList,
  sourceList,
  fieldId,
  showID,
  excludeFieldName,
  columnClassName,
  defaultFormatColumn,
  customProps,
  customType,
}) => {
  let _col = {}
  let _item = colExtra
  let _fieldName = fieldName
  //check fieldID
  if (_fieldName == fieldId) {
    let _hidden = true
    if (showID != null && showID == true) {
      _hidden = false
    }
    if (_hidden == true) {
      _col = {
        width: DefaultWidthID,
        hidden: true,
        isKey: true,
        dataField: fieldId,
        header: "ID",
      }
    } else {
      _col = {
        width: _item.Width || DefaultWidthID,
        hidden: false,
        isKey: true,
        dataField: fieldId,
        header: _item.Header || _fieldName,
      }
    }
  } else {
    if (excludeFieldName != null && excludeFieldName.indexOf(_fieldName) > -1) {
      return null
    } else if (_item.CanShow == true) {
      _col = {
        width: _item.Width || DefaultWidthCol,
        dataField: _fieldName,
        header: _item.Header || _fieldName,
        colProps: {},
      }

      //
      if (columnClassName != null) {
        _col.columnClassName = columnClassName
      }
      if (defaultFormatColumn != null) {
        _col.dataFormat = defaultFormatColumn
      } else {
        if (MyTableCellHelpers.Types._auto) {
          _col.dataFormat = MyTableCellHelpers.Types._auto //auto parse type
        } else {
          _col.dataFormat = MyTableCellHelpers.Types._empty
        }
      }

      //parse header
      FlowBuildColTable_BuildOneCol_ParseHeader({
        colExtra,
        colConfig: _col,
        extraData,
        fnList,
      })
      //parse width column
      FlowBuildColTable_BuildOneCol_ParseColWidth({ colExtra, colConfig: _col })
      //parse custom props
      FlowBuildColTable_BuildOneCol_ParseCustomProps({
        colExtra,
        colConfig: _col,
        customProps,
        customType,
        fieldName,
      })
      //format Extra Data
      let _formatExtraData = {
        fieldName: _fieldName,
        extraData: extraData,
        sourceList: sourceList || {},
        fnList: fnList || {},
      }
      _formatExtraData = Object.assign(_formatExtraData, _item)
      _col.colProps.formatExtraData = _formatExtraData
      //
    }
  }
  return _col
}
export const FlowBuildColTableFromExtraData = ({
  extraData,
  fieldId = "Id",
  sourceList,
  fnList = {},
  showID = false,
  excludeFieldName = [],
  columnClassName,
  defaultFormatColumn,
  customProps,
  customType,
} = {}) => {
  console.warn("\n\n\nFlowBuildColTableFromExtraData\n\n\n")
  let _cols = []
  let _extraColumns = {}
  if (extraData && extraData.Columns) {
    _extraColumns = extraData.Columns
  } else {
    //return empty
    return _cols
  }
  //build addExtraColumns
  let _addExtraColumns = FlowBuildColTable_BuildExtraColumns({
    extraData,
    sourceList,
    fnList,
  })
  if (_addExtraColumns) {
    for (let k of Object.keys(_addExtraColumns)) {
      _extraColumns[k] = _addExtraColumns[k]
    }
  }
  //build cols
  let _arrColumns = Object.keys(_extraColumns)
  for (let i = 0; i < _arrColumns.length; i++) {
    let _fieldName = _arrColumns[i]
    let _colExtra = _extraColumns[_fieldName]
    let _col = FlowBuildColTable_BuildOneCol({
      extraData,
      colExtra: _colExtra,
      fieldName: _fieldName,
      fnList,
      sourceList,
      fieldId,
      showID,
      excludeFieldName,
      columnClassName,
      defaultFormatColumn,
      customProps,
      customType,
    })
    if (_col && _col.dataField) {
      if (_colExtra.Index != null) {
        if (_colExtra.Index == 0) {
          _cols.unshift(_col)
        } else {
          _cols.push(_col)
        }
      } else {
        _cols.push(_col)
      }
    }
  }
  //return
  return _cols
}

// colsHide: IsShow, FieldName, GroupFields
export const FlowFilterColsWithHidden = ({ cols, columnsFilterList } = {}) => {
  let _colsHide = columnsFilterList.filter(v => v.IsShow === false)
  let _cols = cols
  for (let i = 0; i < _colsHide.length; i++) {
    _cols = _cols.filter(v => {
      if (_colsHide[i].FieldName) {
        return v.dataField !== _colsHide[i].FieldName
      } else if (_colsHide[i].GroupFields) {
        return _colsHide[i].GroupFields.indexOf(v.dataField) == -1
          ? true
          : false
      }
      return true
    })
  }
  return _cols
}

const getConfigColumnHide = (extraData, fieldName) => {
  let _configFilter = FlowTableExtraData.FlowGetMoreFilterOfCellFromExtraData(
    extraData,
    fieldName
  )
  if (_configFilter && _configFilter.columnHide) {
    return _configFilter.columnHide
  }
}

export const FlowBuildColumnHidden = ({ cols, extraData }) => {
  if (cols) {
    let _cFL = []
    let _group = {}
    for (let i = 0; i < cols.length; i++) {
      const _item = cols[i]
      if (_item.dataField !== "Id" && !_item.dataField.startsWith("_")) {
        let _configColumnHide =
          getConfigColumnHide(extraData, _item.dataField) || {}
        if (_configColumnHide.show !== false) {
          if (_configColumnHide.group) {
            if (_group[_configColumnHide.group] == null) {
              _group[_configColumnHide.group] = {
                Title: _configColumnHide.group,
                GroupFields: [_item.dataField],
                IsShow: _configColumnHide.isDefautHide === true ? false : true,
                Styles: _configColumnHide.style,
              }
              _cFL.push(_group[_configColumnHide.group])
            } else {
              _group[_configColumnHide.group].GroupFields.push(_item.dataField)
            }
          } else {
            let _obj = {
              Title: _item.header || _item.dataField,
              IsShow:
                _configColumnHide.isDefautHide === true
                  ? false
                  : _item.isDefautHide === true
                  ? false
                  : true,
              FieldName: _item.dataField,
              Styles: _configColumnHide.style,
            }
            _cFL.push(_obj)
          }
        }
      }
    }
    console.warn("cFL", _cFL)
    return _cFL
  }
}

export const FlowGetClassNameTable = (fnList = {}) => {
  let _configPageTable = fnListMyPage.fnGetConfigPageTable(fnList, [])
  let _className =
    _configPageTable[KConfigPageTable.showHeader] !== false
      ? ""
      : "s_table_hideheader"
  _className +=
    _configPageTable[KConfigPageTable.hasCustomHeader] === true
      ? " s_table_customheader"
      : ""
  _className +=
    _configPageTable[KConfigPageTable.hasWrapHeader] === true
      ? " s_table_wrapheader"
      : ""
  _className += _configPageTable[KConfigPageTable.noBorder]
    ? " s_table_noborder"
    : ""
  _className += _configPageTable[KConfigPageTable.noBorderCol]
    ? " s_table_noborder_col"
    : ""
  _className += _configPageTable[KConfigPageTable.borderLight]
    ? " s_table_borderlight"
    : ""
  _className += _configPageTable[KConfigPageTable.hasBigBorderBottom]
    ? " has-big-borderbottom"
    : ""
  _className += " " + (_configPageTable[KConfigPageTable.classNameTable] || "")
  _className += _configPageTable[KConfigPageTable.forceFullExpandDetail]
    ? " s_table_fullexpanddetail"
    : ""
  return _className
}

/** Render Page Table
 * -  Get Options
 * -  Get Data List
 * -  Render Header
 * -    Render Header Buttons
 * -      Render Header Button - link
 * -      Render Header Button - api
 * -      Render Header Button - form
 * -  Render Search
 * -  Render Filter
 * -    Render Filter Items
 * -      Render Filter Item - select
 * -      Render Filter Item - select2
 * -      Render Filter Item - checkbox
 * -      Render Filter Item - datefromto
 * -  Render Chart (option)
 * -  Render Table
 * -    Render Table Cell
 * -      Render Table Cell - expand_multi
 * -      Render Table Cell - delete
 * -      Render Table Cell - readonly
 * -      Render Table Cell - textarea
 * -      Render Table Cell - text
 * -      Render Table Cell - date
 * -      Render Table Cell - select
 * -      Render Table Cell - select2
 */
