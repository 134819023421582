import { HLog } from "@macashipo/mlib"

const logApi = HLog.logApi
const logExistType = HLog.logExistType
const logNoAnyType = HLog.logNoAnyType
const logOne = HLog.logOne
const logParseJson = HLog.logParseJson
const logTest = HLog.logTest
const logW = HLog.logW
export {
  logApi,
  logExistType,
  logNoAnyType,
  logOne,
  logParseJson,
  logTest,
  logW,
}
