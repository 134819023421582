import React, { lazy, Suspense } from "react"
import { Router, Switch, Route } from "react-router-dom"
import { HLink, HConfig } from "src/core/helpers"
import AuthenticatedGuard from "src/guards/AuthenticatedGuard"
import PageGeneric from "src/pages/PageGeneric/PageGeneric"
// import PageNotFound from "src/pages/PageNotFound"
import { PATH } from "src/helpers/paths"
import { HCF } from "@macashipo/mlib"
import { KUI } from "src/helpers/K"
import Loading from "src/components/Loading/Loading"
// import MainLayout from "src/layouts/MainLayout"
import MainLayout from "src/modules/adminpanel/MyLayout/Types/architect"
// import { CSuppense } from "src/core/components"
import MyPage from "src/core/components/MyPage/MyPage"
// const Home = lazy(() => import("src/pages/Home/Home"))
import Login from "src/pages/Login/Login"
import Forgot from "src/pages/Login/Forgot"
import Reset from "src/pages/Login/Reset"
import Register from "src/pages/Login/Register"
import useForceUpdate from "src/modules/adminpanel/hooks/useForceUpdate"

const PageNotFoundv2 = lazy(() => import("src/pages/PageNotFound/demo2"))

export default function Routes() {
  const forceUpdate = useForceUpdate()
  const history = HLink.getHistory()
  const publicRoutes = HConfig.getPublicRoutes()
  const privateRoutes = HConfig.getPrivateRoutes()
  HCF.setCF(KUI.fnForceUpdateRoutes, forceUpdate)
  console.warn("render Routes:", history)
  return (
    <Router history={history}>
      <Switch>
        <AuthenticatedGuard
          exact
          path={PATH.HOME}
          component={props => {
            const configPage = {
              UIUrl: "/",
              ScreenCode: "Welcome",
              Title: "Welcome",
              APIName: "",
              UIType: "welcome",
              Config: null,
              RequestData: "",
              Id: "",
            }
            return (
              <MainLayout {...props}>
                <MyPage configPage={configPage} />
              </MainLayout>
            )
          }}
        />
        <Route
          path={PATH.LOGIN}
          component={props => (
            <Suspense fallback={<Loading />}>
              <Login {...props} />
            </Suspense>
          )}
        />
        <Route path={PATH.FORGOT} component={() => <Forgot />} />
        <Route path={PATH.REGISTER} component={() => <Register />} />
        <Route path={`${PATH.RESET}/:tokenkey`} component={() => <Reset />} />
        {privateRoutes?.map((v, i) => {
          return (
            <AuthenticatedGuard
              key={i}
              path={v.UIUrl || ""}
              component={props => (
                <PageGeneric {...props} configPage={v} isRootLevel={true} />
              )}
            />
          )
        })}
        <AuthenticatedGuard
          key={"homeclient"}
          path={"/home"}
          component={props => (
            <PageGeneric
              {...props}
              configPage={{
                UIUrl: "/home",
                ScreenCode: "PageHomeClient",
                Title: "Home",
                APIName: "",
                UIType: "admin_homeclient",
                Config: null,
                RequestData: "",
                Id: "",
              }}
            />
          )}
        />
        {publicRoutes?.map((v, i) => {
          return (
            <Route
              key={"public"} //su dung trung key de ko bi load lai
              path={v.UIUrl || ""}
              render={props => {
                return (
                  <PageGeneric {...props} configPage={v} isRootLevel={true} />
                )
              }}
            />
          )
        })}
        <AuthenticatedGuard
          key={"all"}
          path={"*"}
          exact={true}
          componentGuest={props => {
            return (
              <Suspense fallback={<Loading />}>
                <PageNotFoundv2 {...props} />
              </Suspense>
            )
          }}
          component={props => {
            const configPage = {
              UIUrl: "/",
              ScreenCode: "PageNotFound",
              Title: "Page not found",
              APIName: "",
              UIType: "admin_pagenotfound",
              Config: null,
              RequestData: "",
              Id: "",
            }
            return (
              <MainLayout {...props}>
                <MyPage configPage={configPage} />
              </MainLayout>
            )
          }}
        />
        {/* <Route
          key={"all"}
          path={"*"}
          exact={true}
          render={props => {
            return (
              <Suspense fallback={<Loading />}>
                <PageNotFoundv2 />
              </Suspense>
            )
          }}
        /> */}
      </Switch>
    </Router>
  )
}
