import { HModules, CSuppense, HUtils } from "@macashipo/mlib"
import MyPageOne from "./MyPage"
const ModuleAppDemo = {
  _key: "appdemo",
  _fnList: {},
  _config: {},
  getMyKey: () => {
    return "appdemo"
  },
  runAfterInit: HConfig => {
    HModules.initDefault(ModuleAppDemo, {
      MyPageOne,
    })
    CSuppense.LazyLoading = () => {
      return (
        <div className="mui-lazy-loading ani-fadeIn">
          <i className="fa fa fa-circle-o-notch fa-spin" />
        </div>
      )
    }
    HUtils.runFuntion(HConfig, "addPrivateRoutes", [
      [
        {
          Id: "css_2react_style",
          UIUrl: "/2react-style",
          UIType: "css_2react_style",
        },
        {
          Id: "api_document",
          UIUrl: "/demo/api_document",
          UIType: "api_document",
        },
      ],
    ])
  },
}

export default ModuleAppDemo
