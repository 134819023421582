import React from "react"
import { MyPageHelpers } from "@macashipo/mlib"

const MP_Css_2React_Style = React.lazy(() => import("./Types/css_2react_style"))
const MP_API_Document = React.lazy(() => import("./Types/api_document"))
const List = {
  css_2react_style: MP_Css_2React_Style,
  api_document: MP_API_Document,
}
const MyPageOne = {
  init() {
    MyPageHelpers.addTypesFromListLazy(List)
  },
}

export default MyPageOne
