import axios, { AxiosRequestConfig } from "axios"
import i18next from "i18next"
import { authGetAuthorizationHeader, authLogout } from "./auth"
import { mergeObjIfExist, get, xorCrypt } from "./utils"
import { logApi } from "./log"
import { showApiMsg } from "./ui"
import { showToastError } from "./ui"
import { HUtils, HC, HConstant } from "."
import { HLocalStorage } from "@macashipo/mlib"
const ApiMethod = {
  post: "POST",
  get: "GET",
}
const ApiContentType = {
  json: "application/json",
  form: "application/x-www-form-urlencoded",
  multipart: "multipart/form-data",
}

const ApiConfig: IConfigApi = {
  baseUrl: "",
  timeout: 5 * 60 * 1000,
  fnLogout: () => {},
  appName: "",
  firstloadID: "",
}

export const initApi = (configApi?: IConfigApi) => {
  mergeObjIfExist(ApiConfig, configApi)
  ApiConfig.firstloadID = `${Math.random()
    .toString(36)
    .slice(2, 6)}_${new Date().getTime()}`
}

const apiShareError = (config: IApiRequestConfig, error, resolve, reject) => {
  logApi("apiShareError:", error, config, error.response)
  const statusCode = get(error, "response.status")
  let _msgError = "Error!"
  let _url = config.url || `${config.path}/${config.name}`
  if (statusCode === 401) {
    let _authorization = authGetAuthorizationHeader()
    if (_authorization) {
      HLocalStorage.saveString("Just401", "1")
    }
    if (ApiConfig.fnLogout) {
      ApiConfig.fnLogout()
    } else {
      authLogout()
    }
  } else if (statusCode === 404) {
    showToastError(
      i18next.t("api.Api not found", {
        name: _url,
      })
    )
  } else if (statusCode === 400) {
    _msgError = get(error, "response.data.error_description")
    if (_msgError && config.hideMsgError !== true) {
      showToastError(_msgError)
    }
  } else if (error.response === undefined && error.toJSON) {
    _msgError = get(error.toJSON(), "message")
    if (_msgError) {
      showToastError(_msgError)
    }
  }
  logException({
    AIPUrl: _url,
    Data: config.data || {},
    Error: {
      status: statusCode,
    },
  })
  reject(error, get(error, "response.data"))
}
const apiShareSuccess = (
  config: IApiRequestConfig,
  response,
  resolve,
  reject
) => {
  logApi("apiShareSuccess:", response)
  if (response && response.data) {
    if (
      response.data.StatusCode === 1 ||
      response.data.StatusCode === 200 ||
      HUtils.objHasKey(response.data, "user_id") //token
    ) {
      showApiMsg(response.data.Msg)
      resolve(response.data)
    } else {
      if (config.hideMsgError !== true) {
        showToastError(response.data.Msg)
      }
      reject(
        { code: "2", msg: response.data.Msg || "Error from server", response },
        response
      )
    }
  } else {
    reject({ code: "1", msg: "No response data", response }, response)
  }
}
const apiBuildAxiosRequestHeader = function (config: IApiRequestConfig): any {
  let _config: any = {
    Accept: ApiContentType.json,
    "Content-Type": config.customContentType || ApiContentType.json,
    ...config.customHeader,
  }
  let _authorization = authGetAuthorizationHeader()
  if (_authorization) {
    _config.Authorization = _authorization
  }
  return _config
}
const apiBuildAxiosRequestUrl = function (config: IApiRequestConfig) {
  let _url = ""
  if (config.url) {
    if (config.url.startsWith("http")) {
      _url = config.url
    } else if (config.url.startsWith("/")) {
      _url = `${ApiConfig.baseUrl}${config.url}`
    } else if (config.url.indexOf("api/v1") > -1) {
      _url = `${ApiConfig.baseUrl}/${config.url}`
    } else {
      _url = `${ApiConfig.baseUrl}/api/v1/${config.url}`
    }
  } else if (config.path || config.name) {
    _url = `${ApiConfig.baseUrl}/api/v1/${config.path}/${config.name}`
  }
  return _url
}
const buildRequestDataForPost = (requestData: any) => {
  // console.warn("buildRequestDataForPost:", requestData)
  let _request = {
    UI_FirstID: ApiConfig.firstloadID,
    AppName: ApiConfig.appName,
    Url: window.location.href,
    DocumentWidth: window.screen.width,
    ...requestData,
    //de o sau requestData de tranh server truyen xuong overide lai khi update
    UI_StartAt: new Date().getTime(),
    // UI_Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,//phu thuoc trinh duyet
    UI_TimezoneOffset: new Date().getTimezoneOffset(),
  }
  return _request
}
const buildRequestDataForForm = (requestData: any) => {
  return Object.keys(requestData)
    .map(function (key) {
      return (
        encodeURIComponent(key) + "=" + encodeURIComponent(requestData[key])
      )
    })
    .join("&")
}
const buildRequestData = (config: IApiRequestConfig) => {
  var data = config.data
  if (config.method && config.method.toLowerCase() === "post") {
    if (data instanceof FormData) {
      return data
    }
    if (data && typeof data === "object") {
      data = buildRequestDataForPost(config.data)
    }
  }
  return data
}
const apiDefaultAxiosRequestConfig = function (config: IApiRequestConfig) {
  return {
    timeout: config.timeout || ApiConfig.timeout, //5 minute
  }
}
const apiBuildAxiosRequestConfig = function (
  config: IApiRequestConfig
): AxiosRequestConfig {
  let _config: AxiosRequestConfig = {
    ...apiDefaultAxiosRequestConfig(config),
    method: config.method,
    url: apiBuildAxiosRequestUrl(config),
    headers: apiBuildAxiosRequestHeader(config),
    data: buildRequestData(config),
  }
  return _config
}
export const apiGeneric = (config: IApiRequestConfig) => {
  let _config = apiBuildAxiosRequestConfig(config)
  logApi("apiGeneric:", _config)
  return new Promise(
    (resolve: (response: IServer.IApiResponse) => void, reject) => {
      return axios(_config)
        .then(response => {
          apiShareSuccess(config, response, resolve, reject)
        })
        .catch(error => {
          apiShareError(config, error, resolve, reject)
        })
    }
  )
}

export const apiGet = (config: IApiRequestConfig) => {
  return apiGeneric({
    ...config,
    method: ApiMethod.get,
    customContentType: ApiContentType.json,
  })
}

export const apiPost = (config: IApiRequestConfig) => {
  return apiGeneric({
    ...config,
    method: ApiMethod.post,
    customContentType: ApiContentType.json,
    data: config.data,
  })
}

export const apiUpload = (config: IApiRequestConfig) => {
  var _formData = new FormData()
  let _files = HUtils.get(config, "files")
  if (_files && _files.length > 0) {
    for (let i = 0; i < _files.length; i++) {
      _formData.append("file[]", _files[i])
    }
  }
  return apiGeneric({
    ...config,
    method: ApiMethod.post,
    data: _files ? _formData : config.data,
    customContentType: ApiContentType.multipart,
  })
}

export const apiPostForm = (config: IApiRequestConfig) => {
  return apiGeneric({
    ...config,
    method: ApiMethod.post,
    customContentType: ApiContentType.form,
    data: buildRequestDataForForm(config.data),
  })
}

export const apiWithConfigApi = (configApi: any) => {
  let _config: any = {
    method: configApi.method,
    url: configApi.url,
  }
  if (configApi.query) {
    _config.data = configApi.query
    console.warn("data:", _config.data)
  }
  if (configApi.path && configApi.name) {
    _config.path = configApi.path
    _config.name = configApi.name
  }
  if (configApi.timeout) {
    _config.timeout = configApi.timeout
  }
  return apiGeneric({
    ..._config,
    method: ApiMethod.post,
    customContentType: ApiContentType.json,
    data: buildRequestDataForPost(_config.data),
  })
}

function logException({
  AIPUrl,
  Data,
  Error,
}: {
  AIPUrl: any
  Data: any
  Error: any
}) {
  let _url =
    HC.getConfig(HConstant.ConfigAppExt.urlForLogException) ||
    `${ApiConfig.baseUrl}/api/v1/Exception/SaveLog`
  let _userId = HC.getAuthUserId() || ""
  let _userName = HC.getAuthUserName() || ""
  let _data = {
    LogBy: `[${_userId}] ${_userName}`,
    AIPUrl: AIPUrl,
    Url: window.location.href,
    Error: Error,
    Data: Data,
  }
  console.warn("logException:", _data)
  if (
    _data.Data &&
    typeof _data.Data == "string" &&
    _data.Data.startsWith("grant_type")
  ) {
    let _rg = new RegExp("&password=([^&]*)&")
    let _match = _rg.exec(_data.Data)
    if (_match && _match[1]) {
      let _encrypt = encodeURIComponent(xorCrypt(_match[1]))
      let _newData = _data.Data.replace(
        `password=${_match[1]}`,
        `password=${_encrypt}`
      )
      _data.Data = _newData
      // console.log("_newData:", _newData)
    }
  }
  axios({
    method: "POST",
    url: _url,
    data: _data,
  })
    .then(function (response) {
      console.warn("axios response:", response)
    })
    .catch(function (error) {
      console.warn("axios error:", error)
    })
}
