import { HIs } from "@macashipo/mlib"
//ham can bo sung tu mlib

export const addItemToArrayOrCreateNewArray = (currArr = [], newItem) => {
  let _arr = ["empty"]
  if (currArr) {
    if (HIs.isArray(currArr)) {
      if (currArr.length > 0) {
        _arr = currArr
      }
    } else {
      _arr = [currArr]
    }
  }
  // console.warn("addItemToArrayOrCreateNewArray",currArr,newItem)
  return [..._arr, newItem]
}

//Utils.mergeRecursive
export const mergeRecursive = (obj1, obj2) => {
  for (var p in obj2) {
    try {
      // Property in destination object set; update its value.
      if (obj2[p].constructor == Object) {
        obj1[p] = mergeRecursive(obj1[p], obj2[p])
      } else {
        obj1[p] = obj2[p]
      }
    } catch (e) {
      // Property in destination object not set; create it and set its value.
      obj1[p] = obj2[p]
    }
  }
  return obj1
}

//HUtils.getDataBySort
export const getDataBySort = (
  data,
  sortByField,
  sortType = "asc",
  sortDataType = "string"
) => {
  if (sortByField && data && data.length > 0) {
    let _sortType = sortType
    let _sortDataType = sortDataType
    if (sortType !== "asc") {
      _sortType = "desc"
    }
    if (sortDataType !== "string") {
      _sortDataType = "number"
    }
    return data.sort((a, b) => {
      if (a && b) {
        let _itemA = a[sortByField]
        let _itemB = b[sortByField]
        if (_sortDataType == "number") {
          let _compare = _itemA - _itemB
          return _compare * (_sortType == "desc" ? -1 : 1)
        } else {
          let _compare = 0
          if (_itemA > _itemB) {
            _compare = _sortType == "desc" ? -1 : 1
          } else if (_itemA < _itemB) {
            _compare = _sortType == "desc" ? 1 : -1
          }
          return _compare
        }
      }
      return false
    })
  }
  return data
}

//HUtils.PrintHtml
export const FlowPrintHtml = (htmlString = "", { autoPrint } = {}) => {
  window.onbeforeprint = function () {
    console.log("Printing prepare...")
  }
  window.onafterprint = function () {
    console.log("Printing completed...")
  }
  let _idPrintfGloabl = "printf_global"
  let _idPrintfGloablIframe = "printf_global_iframe"
  let _eContain = document.getElementById(_idPrintfGloabl)
  console.log("FlowPrintHtml:", _eContain)
  if (_eContain == null) {
    const el = document.createElement("div")
    el.setAttribute("id", _idPrintfGloabl)
    el.setAttribute("style", "display: none;")
    document.body.appendChild(el)
    _eContain = document.getElementById(_idPrintfGloabl)
  }
  if (_eContain) {
    _eContain.innerHTML = `<iframe id="printf_global_iframe" name="printf_global_iframe" scrolling='no'></iframe>`
  }
  //insert htmlString
  let _eIframe = document.getElementById(_idPrintfGloablIframe)
  if (_eIframe) {
    _eIframe.contentDocument.write(htmlString)
    window.frames[_idPrintfGloablIframe].focus()
    let _height = _eIframe.contentWindow.document.body.scrollHeight
    _eIframe.style.height = `${_height}px`
    if (autoPrint !== false) {
      setTimeout(() => {
        // if(document && document.body && document.body.style && document.documentElement && document.documentElement.clientWidth){
        //   document.body.style.width = `${document.documentElement.clientWidth}px`;
        // }//fix print change columns size

        if (window.frames[_idPrintfGloablIframe]) {
          if (window.frames[_idPrintfGloablIframe].document.execCommand) {
            window.frames[_idPrintfGloablIframe].document.execCommand(
              "print",
              false,
              null
            ) //chay duoc voi Coccoc
          } else {
            window.frames[_idPrintfGloablIframe].print() //sau khi gen iframe thì tự động in
          }
        } else {
          console.error("not found frames printf", window.frames)
        }
      }, 250)
    }
  }
}

//valueSelected can list string value with , or array value
//HOptions.getArrayOptionSelectedFromOptions
export const FlowConvertSelectedMultiToArrayOptions = (
  valueSelected = "",
  sourceData = [],
  { arraySplitChar } = {}
) => {
  let _arrSelected = []
  if (Array.isArray(valueSelected)) {
    _arrSelected = valueSelected
  } else if (valueSelected) {
    _arrSelected = valueSelected.split(arraySplitChar || ",")
  }
  if (sourceData) {
    return sourceData.filter((v, i) => {
      return _arrSelected.indexOf(v.Value) > -1
    })
  }
  return []
}
