import { HCF, HLocalStorage, HUtils } from "@macashipo/mlib"
import { HApi } from "../helpers"
import { KStorage, KConfigApp } from "src/helpers/K"
interface IModuleFirstSetting extends ICore.IOneModule {
  requestFirstSetting: () => void
  parseFirstSetting: (data: object) => void
}
const ModuleFirstSetting: IModuleFirstSetting = {
  _key: "first_setting",
  _fnList: {},
  _config: {},
  getMyKey: () => {
    return "first_setting"
  },
  runAfterInit: (HConfig: any) => {
    ModuleFirstSetting.requestFirstSetting()
  },

  requestFirstSetting: () => {
    let _uiAppConfig =
      HUtils.runFuntion(HCF.CF, "fnGetRequestUIAppConfigFirstSetting") || {}
    HApi.apiPost({
      path: "GlobalAppSetting",
      name: "FisrtSetting",
      data: {
        UIAppConfig: _uiAppConfig,
      },
    })
      .then(response => {
        if (response.Data) {
          ModuleFirstSetting.parseFirstSetting(response.Data)
        }
      })
      .catch(error => {})
  },
  parseFirstSetting: data => {
    if (data) {
      HCF.setCFMultiObj(data)
      if (data["DefaultValues"]) {
        HCF.setCFMultiObj(data["DefaultValues"])
      }
      if (data["DefaultHomeURL"]) {
        HCF.setCF(KConfigApp.home_url, data["DefaultHomeURL"])
        HLocalStorage.saveString(KStorage.HomeURL, data["DefaultHomeURL"])
      }
      if (data["MediaAcceptedType"]) {
        HCF.setCF(KConfigApp.fileUploadSupport, data["MediaAcceptedType"])
      }
      if (data["UIAppConfig"]) {
        HCF.setCFMultiObj(data["UIAppConfig"])
      }
    }
  },
}

export default ModuleFirstSetting
