import MyPageOne from "./MyPage"
import { HUtils, HConstant } from "src/core/helpers"
interface IModuleRedirectToken extends IModuleOne {
  runAfterInit: (HConfig) => void
}
const ModuleRedirectToken: IModuleRedirectToken = {
  runAfterInit: HConfig => {
    MyPageOne.init()
    HUtils.runFuntion(HConfig, HConstant.HConfig.FnList.addPublicRoutes, [
      [
        {
          Id: "redirect_token",
          UIUrl: "/redirect",
          UIType: "redirect_token",
          Config: '{"wrapLayout":"blank"}',
        },
      ],
    ])
  },
}

export default ModuleRedirectToken
