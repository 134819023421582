import { ApiGeneric } from "src/services/apiServices"
import { fnListUI } from "src/helpers/fnList"
import { HCF } from "@macashipo/mlib"
export const FlowApiPost = () => {}

export const FlowApiGet = () => {}

export const FlowApiGeneric = ({
  request,
  cbSuccess,
  cbError,
  cbFinal,
  ...rest
} = {}) => {
  //v1
  ApiGeneric({
    request,
    successCallBack: response => {
      let _verAdmin = HCF.getCF("verAdmin")
      if (_verAdmin != "v5") {
        fnListUI.fnShowToast(response.Msg)
      }
      if (cbSuccess) {
        cbSuccess(response)
      }
      if (cbFinal) {
        cbFinal({ response })
      }
    },
    errorCallBack: (error, response) => {
      if (cbError) {
        cbError(error, response)
      }
      if (cbFinal) {
        cbFinal({ error, response })
      }
    },
    ...rest,
  })
}

export const FlowApiUpdateFields = ({
  row,
  apiController,
  apiName = "UpdateFields",
  multiObjUpdate,
  sharedRequestData = {},
  cbSuccess,
  cbError,
} = {}) => {
  //v1
  ApiGeneric({
    request: {
      method: "POST",
      path: apiController,
      name: apiName,
    },
    data: {
      Id: row.Id,
      ProjectId: row.ProjectId,
      RequestData: sharedRequestData,
      Values: Object.keys(multiObjUpdate).map(v => {
        return {
          FieldName: v,
          NewValue: multiObjUpdate[v],
        }
      }),
    },
    successCallBack: response => {
      if (cbSuccess) {
        cbSuccess(response)
      }
    },
    errorCallBack: (error, response) => {
      if (cbError) {
        cbError(error, response)
      }
    },
  })
}

export const FlowApiRefreshCache = () => {
  FlowApiGeneric({
    request: {
      method: "POST",
      path: "Cache",
      name: "Refresh",
    },
    data: {
      Id: "ALL",
    },
    cbSuccess: () => {
      // an di vì v1 ko reload
      // window.location.reload()
    },
  })
}
