import React from "react"
import { CSuppense } from "src/core/components"
import MyModal from "src/core/components/MyModal/MyModal"
import { HConstant, HUtils, HApi } from "src/core/helpers"
import { initI18n } from "./lang/i18n"
import i18next from "i18next"
import MyUIOne from "./MyUI"
import { updateSettingByResponse } from "./helpers"
const Generation2FA = React.lazy(() => import("./MyComponent/Generation2FA"))
const Disable2FA = React.lazy(() => import("./MyComponent/Disable2FA"))
const Setting2FA = React.lazy(() => import("./MyComponent/Setting2FA"))
const Verify2FA = React.lazy(() => import("./MyComponent/Verify2FA"))

interface IModuleSetting2FA extends IModuleOne {
  runAfterInit: (HConfig: any) => void
  requestSetting: () => void
}
const ModuleSetting2FA: IModuleSetting2FA = {
  runAfterInit: HConfig => {
    initI18n()
    MyUIOne.init()
    // ModuleSetting2FA.requestSetting()
    HUtils.runFuntion(
      HConfig,
      HConstant.HConfig.FnList.registerExtSettingParts,
      [
        props => {
          return CSuppense(props, Setting2FA)
        },
      ]
    )
    HConfig.showGenerate2FA = (fromOneMethod: any) => {
      MyModal.showFromComponent(
        props => {
          return CSuppense(
            props,
            fromOneMethod.IsEnable === true ? Disable2FA : Generation2FA
          )
        },
        {
          title:
            fromOneMethod.IsEnable === true
              ? i18next.t("setting2fa:title.disable2fa")
              : i18next.t("setting2fa:title.enable2fa"),
          backdrop: "static",
          size: "md",
          modalCentered: true,
        }
      )
    }
    HConfig.showVerify2FA = fnList => {
      // console.warn("showVerify2FA")
      MyModal.showFromComponent(
        props => {
          return CSuppense(props, Verify2FA)
        },
        {
          title: "Verify",
          backdrop: "static",
          fnList: fnList,
          size: "lg",
          modalCentered: true,
        }
      )
    }
  },
  requestSetting: () => {
    HApi.apiPost({
      path: "Aauth2FA",
      name: "UserSetting",
      data: {},
    })
      .then(response => {
        updateSettingByResponse(response)
      })
      .catch(error => {})
  },
}

export default ModuleSetting2FA
