import classnames from "classnames"
import React from "react"
import { Modal } from "reactstrap"
import { objHasKey } from "src/core/helpers/utils"
import ReactAwesomeLightbox from "src/libs/ReactAwesomeLightbox"
interface IProps {
  className?: any
  style?: any
  type: string
}

interface IState {
  isShow?: boolean
  opts?: IConfigLightbox
}

interface IConfigLightbox {
  component?: any
  showFooter?: boolean
  modalClassName?: string
  modalCentered?: boolean
  modalScrollInside?: boolean
  title?: string
  size?: string
  backdrop?: any
  fnList?: any
  image?: string
  images?: any[]
}

class MyLightbox extends React.Component<IProps, IState> {
  state: any = {
    isShow: false,
    opts: {},
  }
  componentDidMount() {
    const { type } = this.props
    MyLightbox.Helpers.initComponent(type, this)
  }
  show = (opts: any) => {
    this.setState({
      isShow: true,
      opts: { ...MyLightbox.Helpers.defaultConfigModal, ...opts },
    })
  }
  hide = () => {
    this.setState({
      isShow: false,
      opts: {},
    })
  }
  toggle = () => {
    this.setState({
      isShow: !this.state.isShow,
    })
  }
  _getConfig = key => {
    const { opts } = this.state
    if (objHasKey(opts, key)) {
      return opts[key]
    }
  }
  _renderBody = () => {
    const { opts } = this.state
    let _props: any = {}
    if (opts.image) {
      _props.image = opts.image
    } else if (opts.images) {
      _props.images = opts.images
    }
    return (
      <ReactAwesomeLightbox
        {..._props}
        title={opts.title || ""}
        onClose={() => {
          this.hide()
        }}
      />
    )
  }
  _renderHeaderContent = () => {
    const { opts } = this.state
    return opts.title
  }
  _getClassname = () => {
    const { opts } = this.state
    return classnames(opts.modalClassName, {
      "modal-dialog-centered": opts.modalCentered,
      [`modal-${opts.size}`]: true, //sm,lg,xl
      "modal-dialog-scrollable": opts.modalScrollInside,
    })
  }
  render() {
    const { isShow, opts } = this.state
    const { backdrop } = opts
    return (
      <Modal
        isOpen={isShow === true}
        fade={false}
        backdrop={backdrop != null ? backdrop : true}
        toggle={this.toggle}
        className={this._getClassname()}
      >
        {this._renderBody()}
      </Modal>
    )
  }
  static Helpers = {
    defaultConfigModal: {
      usingModalBody: true,
      size: "fullscreen",
    },
    types: {
      lightbox: "lightbox",
    },
    initComponent: (type, component) => {
      if (type && component) {
        MyLightbox.Helpers.components[type] = component
      }
    },
    components: {},
  }
  static showWithOpts = (opts: IConfigLightbox) => {
    if (MyLightbox.Helpers.components[MyLightbox.Helpers.types.lightbox]) {
      MyLightbox.Helpers.components[MyLightbox.Helpers.types.lightbox].show(
        opts
      )
    }
  }
}

export default MyLightbox
