import { combineReducers } from "redux"
import { AppReducer } from "src/App/App.reducer"
import { loginReducer } from "src/pages/Login/Login.reducer"
import { SideMenuReducer } from "./sidemenu/sidemenu.reducer"
import ThemeOptions from "./ThemeOptions"
const rootReducer = combineReducers({
  app: AppReducer,
  login: loginReducer,
  sidemenu: SideMenuReducer,
  ThemeOptions,
})

export default rootReducer
