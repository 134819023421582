import { fnListMyPage } from "src/helpers/fnList"
export const KMore = {
  myui: "myui",
  isServer: "isServer",
  queryType: "queryType",
  isFrom: "isFrom", //for datefromto
  isTo: "isTo", //for datefromto
  showRanges: "showRanges", //for datefromto
  moreDateRangePicker: "moreDateRangePicker", //more props for date range picker
}
export const FlowIsShowFilter = onePage => {
  let _isShow = true
  if (onePage) {
    if (onePage._oneScreenConfig._filter.show === false) {
      _isShow = false
    }
    if (_isShow) {
      if (onePage._filterList && onePage._filterList.length == 0) {
        _isShow = false
      }
    }
  }
  console.warn("showFilter:", _isShow, onePage)
  return _isShow
}

export const FlowIsShowSearch = onePage => {
  let _isShow = true
  if (onePage) {
    if (onePage._oneScreenConfig._filter.showSearch === false) {
      _isShow = false
    }
  }
  console.warn("showSearch:", _isShow, onePage)
  return _isShow
}

export const FlowGetMore = ({ oneFilterControl, ...rest } = {}) => {
  if (oneFilterControl && oneFilterControl.more) {
    return oneFilterControl.more
  }
  if (rest && rest.configOne && rest.configOne && rest.configOne.more) {
    return rest.configOne.more
  }
  return {}
}

export const FlowGetOptionKey = ({ oneFilterControl, ...rest } = {}) => {
  if (oneFilterControl && oneFilterControl.optionKey) {
    return oneFilterControl.optionKey
  }
  if (rest && rest.configOne && rest.configOne && rest.configOne.optionKey) {
    return rest.configOne.optionKey
  }
  return ""
}

export const FlowGetConfigOfMore = ({
  oneFilterControl,
  key,
  defaultValue,
  ...rest
} = {}) => {
  let _defaultValue = defaultValue
  const more = FlowGetMore({ oneFilterControl, ...rest })
  console.warn("mtf more:", more)
  if (more && more.hasOwnProperty(key)) {
    return more[key]
  }
  return _defaultValue
}

export const FlowGetSourceData = ({
  oneFilterControl,
  fnList,
  ...rest
} = {}) => {
  let _sourceList = fnListMyPage.getSourceList(fnList)
  let _optionKey = FlowGetOptionKey({ oneFilterControl, ...rest })
  console.warn("FlowGetSourceData:", _sourceList, _optionKey, fnList)
  if (_sourceList && _optionKey) {
    return _sourceList[_optionKey] || []
  }
  return []
}

/**
 * onePage:
 * _filterList: list filter control arr: OneTableFilterControl
 * _oneFilters: filtes, arr: listFilter, get current filter,
 */
