import React, { useState } from "react"
import { connect, ConnectedProps } from "react-redux"
import { useHistory, Link } from "react-router-dom"
import { Button, Input, InputGroup, InputGroupText } from "reactstrap"
import { MyIcon, MyUI } from "src/core/components"
import { HAuth } from "src/core/helpers"
import { get } from "src/core/helpers/utils"
import { PATH } from "src/helpers/paths"
import { HLocalStorage } from "@macashipo/mlib"
import { fnListConfigApp } from "src/helpers/fnList"
import LoginSocial from "./LoginSocial"
import useForceUpdate from "src/core/hook/useForceUpdate"
import { showToastError } from "src/core/helpers/ui"
import i18next from "i18next"
// import {
//   HiOutlineMail,
//   HiOutlineKey,
//   HiOutlineEye,
//   HiOutlineEyeOff,
// } from "react-icons/hi"
// import IMGlogo from "src/assets/images/logo.png"
import {
  ErrorMessage,
  // Input,
  Form,
  // InputGroup,
  // InputPrefix,
  // InputSuffix,
  SubmitSpan,
  Title,
} from "./Login.styles"
import { login, loginSocial, loginSuccessWithResponse } from "./Login.thunks"
import Container from "./LoginContainer"
import { KStorage } from "src/helpers/K"

const mapStateToProps = state => ({
  loading: state.loading,
  isAuthenticated: state.app.isAuthenticated,
  countRefresh: state.login.countRefresh,
})

const mapDispatchToProps = {
  login,
  loginSocial,
  loginSuccessWithResponse,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

interface Props extends ConnectedProps<typeof connector> {}

const Login = (props: Props) => {
  const {
    login,
    loading,
    loginSuccessWithResponse,
    isAuthenticated,
    countRefresh,
  } = props
  const forceUpdate = useForceUpdate
  const fa2_needenable = HAuth.getInfo2FA()["fa2_needenable"]
  const fa2_needverify = HAuth.getInfo2FA()["fa2_needverify"]
  const history = useHistory()
  if (isAuthenticated) {
    console.warn("after login success")
    let _redirectFromLogin = HLocalStorage.getString(
      KStorage.redirectAfterLogin,
      ""
    )
    if (_redirectFromLogin) {
      HLocalStorage.removeItem(KStorage.redirectAfterLogin)
      history.push(_redirectFromLogin)
    } else {
      history.push(PATH.HOME)
    }
  }
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const [error, setError] = useState("")
  // const logoApp =
  //   "https://lh3.googleusercontent.com/d/1xeCHhwlaiijnfiURlef2d0m-OTVr68JR=w0?authuser=0"
  const handleUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value)
  }

  const handlePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)
  }

  const submit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (!loading) {
      const payload = { username, password }
      login(payload)
        .then(res => {
          console.warn("after login")
          forceUpdate()
        })
        .catch(err => {
          setError(get(err, "error.response.data.error_description"))
        })
    }
  }
  const onShowPassword = value => {
    setShowPassword(value)
    let _inputPassword = document.getElementById("password")
    if (_inputPassword) {
      if (value) {
        _inputPassword["type"] = "text"
      } else {
        _inputPassword["type"] = "password"
      }
    }
  }

  React.useEffect(() => {
    var just401 = HLocalStorage.getString("Just401", "")
    if (just401 == "1") {
      localStorage.removeItem("Just401")
      showToastError(i18next.t("login.401msg"))
    }
  }, [])
  console.info("render login", props)
  return (
    <Container>
      {fa2_needenable === true || fa2_needverify === true ? (
        <Form
          className="p-5 rounded-sm shadow text-center"
          onSubmit={e => {
            e.preventDefault()
          }}
        >
          <MyUI
            type="setting2fa_selectmethod2fa"
            fnList={{
              dispatchLoginSuccess: res => {
                loginSuccessWithResponse(res)
              },
            }}
          />
        </Form>
      ) : (
        <div>
          <Form className="p-5 rounded-sm shadow text-center" onSubmit={submit}>
            <Title style={{ marginTop: "0px" }}>
              {i18next.t("login.Login Now")}
            </Title>
            <LoginSocial {...props} />
            <InputGroup style={{ marginBottom: "1rem" }}>
              <InputGroupText>
                <MyIcon data="type:HiOutlineMail" />
              </InputGroupText>
              <Input
                placeholder={i18next.t("login.Username")}
                autoComplete="on"
                value={username}
                autoFocus
                name="username"
                onChange={handleUsername}
              />
            </InputGroup>
            <InputGroup style={{ marginBottom: "1rem" }}>
              <InputGroupText>
                <MyIcon data="type:HiOutlineKey" />
              </InputGroupText>
              <Input
                type={showPassword === true ? "text" : "password"}
                placeholder={i18next.t("login.Password")}
                autoComplete="on"
                value={password}
                name="password"
                onChange={handlePassword}
              />
              <InputGroupText
                onClick={() => {
                  onShowPassword(!showPassword)
                }}
              >
                <MyIcon
                  data={
                    showPassword ? "type:HiOutlineEye" : "type:HiOutlineEyeOff"
                  }
                />
              </InputGroupText>
            </InputGroup>
            {error && (
              <ErrorMessage className="mb-3 text-danger text-xl-center">
                {error}
              </ErrorMessage>
            )}
            <Button type="submit" color="primary" style={{ width: "100%" }}>
              {i18next.t("login.Login")} <SubmitSpan>{"→"}</SubmitSpan>
            </Button>
            <div
              style={{
                marginTop: "0.5rem",
                display: "flex",
                alignContent: "center",
                justifyContent: "space-between",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {fnListConfigApp.getHasForgotPassword() ? (
                <Link to={PATH.FORGOT}>
                  {i18next.t("login.Forgot password?")}
                </Link>
              ) : (
                <div></div>
              )}
              {fnListConfigApp.getHasRegisterAccount() && (
                <Link to={PATH.REGISTER}>
                  {i18next.t("login.Create Account")}
                </Link>
              )}
            </div>
          </Form>
        </div>
      )}
    </Container>
  )
}

export default connector(Login)
