import { HUtils, HJson, HLocalStorage, HCF, HEE } from "@macashipo/mlib"
import { HAuth, HConstant } from "src/core/helpers"
import { KConfigApp, KEventsApp } from "src/helpers/K"
import ApiGeneric from "src/services/apiGeneric"

const kSideMenu = "SideMenu"
const kAppMenu = "AppMenu"
const kAppLogo = "AppLogo"
const kSideMenuHCF = "UI_SideMenu"
const kAppMenuHCF = "UI_AppMenu"
const ModuleProjectScreen = {
  init: HConfig => {
    ModuleProjectScreen.initLastSaved()
    if (HAuth.authIsLogged()) {
      ModuleProjectScreen.requestProjectScreens()
    } else {
      HUtils.runFuntion(HConfig, HConstant.HConfig.FnList.registerAfterLogin, [
        () => {
          console.warn("ModuleProjectScreen: call request when after login ")
          ModuleProjectScreen.requestProjectScreens()
        },
      ])
    }
  },
  initLastSaved: () => {
    const sidemenu = HLocalStorage.getString(kSideMenu, "")
    const appmenu = HLocalStorage.getString(kAppMenu, "")
    const appLogo = HLocalStorage.getString(kAppLogo, "")
    if (sidemenu) {
      ModuleProjectScreen.parseData({
        side_menu: sidemenu,
        app_menu: appmenu,
        appLogo_url: appLogo,
      })
    }
  },
  requestProjectScreens: () => {
    ApiGeneric({
      method: "POST",
      path: "ProjectScreenHeader",
      name: "ProjectScreens",
      data: {},
    })
      .then(response => {
        if (response.Data) {
          ModuleProjectScreen.parseData(response.Data)
        }
      })
      .catch(error => {})
  },
  updateRoutes: data => {},
  parseData: data => {
    let _sideMenu = []
    let _app_menu = []
    if (data.side_menu) {
      _sideMenu = HJson.getObj(data.side_menu, [])
      HLocalStorage.saveString(kSideMenu, data.side_menu)
    }
    if (data.app_menu) {
      _app_menu = HJson.getObj(data.app_menu, [])
      HLocalStorage.saveString(kAppMenu, data.app_menu)
    }
    if (data.appLogo_url) {
      HCF.setCF(KConfigApp.appLogo_url, data.appLogo_url)
      HLocalStorage.saveString(kAppLogo, data.appLogo_url)
    }
    HCF.setCF(kSideMenuHCF, _sideMenu)
    HCF.setCF(kAppMenuHCF, _app_menu)
    console.warn("parseData:", data, _sideMenu, _app_menu)
    HEE.emit(KEventsApp.ee_sidemenu_forceupdate)
  },
}

export default ModuleProjectScreen
