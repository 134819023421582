import { HFormat, HIs, HCF, HColor } from "@macashipo/mlib"
import HC from "./c"
import { KConfigApp } from "src/helpers/K"
import moment from "moment"

HFormat.Types["date"] = {
  format: (value: any, configFormat: any) => {
    // console.warn("format date:", value, configFormat)
    if (value != null && HIs.isDateValue(value)) {
      return moment(value).format(
        configFormat.format || HC.getDefaultFormatDate()
      )
    }
    return ""
  },
}

HFormat.Types["datetime"] = {
  format: (value: any, configFormat: any) => {
    // console.warn("format date:", value, configFormat)
    if (value != null && HIs.isDateValue(value)) {
      return moment(value).format(
        configFormat.format || HC.getDefaultFormatDateTime()
      )
    }
    return ""
  },
}

HFormat.Types["currency"] = {
  format: (value: any, configFormat: any) => {
    let _num = Number(value)
    if (!isNaN(_num)) {
      let _s = _num.toLocaleString(HCF.getCF(KConfigApp.numberLocale), {
        style: "decimal",
      })
      return _s
    }
    return ""
  },
}

HFormat.Types["decimal"] = {
  format: (value: any, configFormat: any) => {
    let _num = Number(value)
    if (!isNaN(_num)) {
      let _s = _num.toLocaleString(HCF.getCF(KConfigApp.numberLocale), {
        style: "decimal",
      })
      return _s
    }
    return ""
  },
}

HFormat.Types["numColByWidth"] = {
  format: (value: any, configFormat: any) => {
    // console.warn("format:", value, configFormat)
    if (HIs.isNumber(value)) {
      return value
    } else if (HIs.isObject(value)) {
      let _objRes = value
      if (value.default) {
        _objRes = value.default
      }
      const windowWidth = (window && window.innerWidth) || Infinity
      let matchedBreakpoint = Infinity
      for (let k of Object.keys(value)) {
        const optBreakpoint = parseInt(k)
        const isCurrentBreakpoint =
          optBreakpoint > 0 && windowWidth <= optBreakpoint
        if (isCurrentBreakpoint && optBreakpoint < matchedBreakpoint) {
          matchedBreakpoint = optBreakpoint
          _objRes = value[k]
        }
      }
      if (HIs.isNumber(_objRes)) {
        return _objRes
      }
    }
    return configFormat.defaultValue || 5
  },
}

//copy from v1
HFormat.Types["number_locale"] = {
  format: (value, configFormat) => {
    let _num = Number(value)
    if (!isNaN(_num)) {
      let _numberLocale = HCF.getCF(KConfigApp.numberLocale)
      if (configFormat.numberLocale) {
        _numberLocale = configFormat.numberLocale
      }
      let _s = _num.toLocaleString(_numberLocale, {
        style: configFormat.style != null ? configFormat.style : "decimal",
        currency: configFormat.currency != null ? configFormat.currency : "USD",
        minimumFractionDigits:
          configFormat.minimumFractionDigits != null
            ? configFormat.minimumFractionDigits
            : 0,
        maximumFractionDigits:
          configFormat.maximumFractionDigits != null
            ? configFormat.maximumFractionDigits
            : 2,
      })
      return _s
    }
    return ""
  },
}
HFormat.Types["configByValue"] = {
  format: (value, configFormat) => {
    let _configByValue = configFormat.configByValue
    if (_configByValue) {
      if (value != null && _configByValue[value]) {
        return _configByValue[value]
      } else if (value === null && _configByValue["_null"]) {
        return _configByValue["_null"]
      } else if ((value === "" || value === null) && _configByValue["_empty"]) {
        return _configByValue["_empty"]
      }
    }
  },
}
HFormat.Types["colorTextByBackground"] = {
  format: (value, configFormat) => {
    let _colorForDark = configFormat.colorForDark || "#ffffff"
    let _colorForLight = configFormat.colorForLight || "#000000"
    if (configFormat && configFormat.fix && configFormat.fix[value]) {
      //tra ve theo danh sach
      return configFormat.fix[value]
    }
    let _lumina = HColor.getLumina(value)
    if (_lumina < 128) {
      return _colorForDark
    }
    return _colorForLight
  },
}
HFormat.Types["number_percent"] = {
  format: (value, configFormat) => {
    let _num = Number(value)
    if (!isNaN(_num)) {
      //can nhan 100 cho TH: 0.5 => 50%
      if (configFormat.needMultip) {
        _num = _num * 100
      }
      //so le hien thi
      if (configFormat.numDecimal) {
        let _x = 10
        if (configFormat.numDecimal > 1 && configFormat.numDecimal <= 5) {
          _x = Math.pow(10, configFormat.numDecimal)
        }
        _num = Math.round(_num * _x) / _x
      }
      let _numberLocale = HCF.getCF(KConfigApp.numberLocale)
      if (configFormat.numberLocale) {
        _numberLocale = configFormat.numberLocale
      }
      let _s = _num.toLocaleString(_numberLocale, {
        style: "decimal",
        minimumFractionDigits:
          configFormat.minimumFractionDigits != null
            ? configFormat.minimumFractionDigits
            : 0,
        maximumFractionDigits:
          configFormat.maximumFractionDigits != null
            ? configFormat.maximumFractionDigits
            : 2,
      })
      let _suffix = "%"
      if (configFormat.suffix != null) {
        _suffix = configFormat.suffix
      }
      return `${_s}${_suffix}`
    }
    return ""
  },
}

HFormat.Types["eval"] = {
  format: (value: any, configFormat: any) => {
    console.warn("format eval:", value, configFormat)
    if (configFormat.eval) {
      var result = configFormat.eval()
      return result || ""
    }
    return ""
  },
}
export default HFormat
