import React from "react"
import { MyTableHeaderHelpers, HFormat } from "@macashipo/mlib"

const MTHstyled = React.lazy(() => import("./Types/styled"))
const MTHhtml = React.lazy(() => import("./Types/html"))
const MTHtooltip = React.lazy(() => import("./Types/tooltip"))
const List = {
  styled: MTHstyled,
  html: MTHhtml,
  tooltip: MTHtooltip,
}
const MyTableHeaderOne = {
  init() {
    MyTableHeaderHelpers.addTypesFromListLazy(List)
  },
}

export default MyTableHeaderOne
