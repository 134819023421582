import { fnListMyPage } from "src/helpers/fnList"
//share query for options, list, query api, form in header button, query all in cell, query all for detail table, query for child content page
export const FlowGetSharedQueryOfPage = (
  configPage,
  {
    onePage,
    match,
    defaultQuery,
    fnListOfParent,
    moreQuery,
    queryFilter,
    querySort,
    from,
  } = {}
) => {
  let _query = defaultQuery || {}
  //from url neu co
  if (match && match.params) {
    _query = { ..._query, ...match.params }
  }
  //ke thua tu parent
  if (fnListOfParent) {
    let _queryFromParent = fnListMyPage.fnGetSharedRequestData(fnListOfParent, [
      { from },
    ])
    if (_queryFromParent) {
      _query = { ..._query, ..._queryFromParent }
    }
  }
  //from configPage.RequestData
  if (configPage && configPage.RequestData) {
    _query = { ..._query, ...configPage.RequestData }
  }
  //from filter, sort neu co
  if (queryFilter) {
    _query = { ..._query, ...queryFilter }
  }
  if (querySort) {
    _query = { ..._query, ...querySort }
  }
  //from configPage
  if (configPage && configPage.Id) {
    _query.ScreenGUID = configPage.Id
  }
  //from moreQuery
  if (moreQuery) {
    _query = { ..._query, ...moreQuery }
  }
  // console.warn("FlowGetSharedQueryOfPage:",_query,from)
  return _query
}

export const FlowGetMoreQueryFilterOfTable = () => {
  let _query = {}
  //export FilterQuery
  return _query
}

export const FlowGetMoreQuerySortOfTable = () => {
  let _query = {}
  //export SortQuery
  return _query
}
