import React from "react"
import { MyBaseHelpers } from "@macashipo/mlib"
import MB_text from "./Types/text"
import MB_textarea from "./Types/textarea"
import MB_select from "./Types/select"

const MB_textareafull = React.lazy(() => import("./Types/textareafull"))
const MB_numeral = React.lazy(() => import("./Types/numeral"))
const MB_select2 = React.lazy(() => import("./Types/select2"))
const MB_switch = React.lazy(() => import("./Types/switch"))
const MB_checkbox = React.lazy(() => import("./Types/checkbox"))
const MB_checkbox_yesno = React.lazy(() => import("./Types/checkbox_yesno"))
const MB_date_flatpickr = React.lazy(() => import("./Types/date_flatpickr"))
const MB_datefromto_flatpickr = React.lazy(() =>
  import("./Types/datefromto_flatpickr")
)
const MB_daterangepicker = React.lazy(() => import("./Types/daterangepicker"))
const MB_monacoeditor = React.lazy(() => import("./Types/monacoeditor"))

const ListNoLazy = {
  text: MB_text,
  textarea: MB_textarea,
  select: MB_select,
}
const List = {
  monacoeditor: MB_monacoeditor,
  daterangepicker: MB_daterangepicker,
  textareafull: MB_textareafull,
  numeral: MB_numeral,
  select2: MB_select2,
  switch: MB_switch,
  checkbox: MB_checkbox,
  checkbox_yesno: MB_checkbox_yesno,
  date_flatpickr: MB_date_flatpickr,
  datefromto_flatpickr: MB_datefromto_flatpickr,
}
const MyBaseOne = {
  init() {
    MyBaseHelpers.addTypesFromListWithoutLazy(ListNoLazy)
    MyBaseHelpers.addTypesFromListLazy(List)
  },
}

export default MyBaseOne
