import React from "react"
import { MyUI, MyLayout } from "@macashipo/mlib"
import { getTypeMyLayout, getTypeMyUI, getStyle } from "./_helpers"
class MyUI_Data_Array extends React.Component {
  render() {
    const { data } = this.props
    const config = this.props.config || {}
    if (data && Array.isArray(data)) {
      return (
        <MyLayout
          type={getTypeMyLayout(config)}
          className={config.className}
          style={getStyle(this.props)}
          {...config.moreProps}
        >
          {data.map((v, i) => {
            let _configChild = config.configChild || {}
            // console.warn("child:", _configChild, v, config.type)
            return (
              <MyUI
                {...this.props}
                key={i}
                type={getTypeMyUI(_configChild)}
                data={v}
                config={_configChild}
                {..._configChild.moreProps}
              />
            )
          })}
        </MyLayout>
      )
    }
    return <div className={config.className} style={config.style}></div>
  }
}

export default MyUI_Data_Array
