import { MyChartHelpers } from "@macashipo/mlib"
import React from "react"

const MCH_gg_column = React.lazy(() => import("./Types/gg_column"))
const List = {
  gg_column: MCH_gg_column,
}
const MyChartOne = {
  init() {
    MyChartHelpers.addTypesFromListLazy(List)
  },
}

export default MyChartOne
