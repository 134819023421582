import React from "react"
import MyUIHelpers from "src/core/components/MyUI/MyUIHelpers"
import { CSuppense } from "src/core/components"

const MUIGeneration2FA = React.lazy(
  () => import("../MyComponent/Generation2FA")
)
const MUIVerify2FA = React.lazy(() => import("../MyComponent/Verify2FA"))
const MUICheck2FA = React.lazy(() => import("../MyComponent/Check2FA"))
const MUISelectMethod2FA = React.lazy(
  () => import("../MyComponent/SelectMethod")
)
const MyPageOne = {
  init() {
    MyUIHelpers.addTypes("setting2fa_generation2fa", props => {
      return CSuppense(props, MUIGeneration2FA)
    })
    MyUIHelpers.addTypes("setting2fa_verify2fa", props => {
      return CSuppense(props, MUIVerify2FA)
    })
    MyUIHelpers.addTypes("setting2fa_check2fa", props => {
      return CSuppense(props, MUICheck2FA)
    })
    MyUIHelpers.addTypes("setting2fa_selectmethod2fa", props => {
      return CSuppense(props, MUISelectMethod2FA)
    })
  },
}

export default MyPageOne
