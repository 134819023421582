import { fnListUI } from "src/helpers/fnList"
export const RoutesPublic = []
export const RoutesPrivate = []
export const RoutesTest = []

/**
 * Route Config:
 * {UIUrl:'/demo',ScreenCode:'',Title:'',APIName:'API',UIType:'Client', Config: '{"type":"solid_wpgstandard_searchv3","test":true}'},
 */

//gan forceupdate vao HCF, goi force update tu HCF
export const FlowForceUpdateRoutes = () => {
  fnListUI.fnForceUpdateRoutes()
}

//from categoryScreenList
export const FlowParseCategoryScreenList = () => {}

//from first setting
export const FlowParsePublicApiScreenList = () => {}

const AddRoutes = (list = [], arrConfigRoute) => {
  if (arrConfigRoute && arrConfigRoute.length > 0) {
    for (let i = 0; i < arrConfigRoute.length; i++) {
      list.push(arrConfigRoute[i])
    }
  }
  FlowForceUpdateRoutes()
}
export const FlowAddPublicRoute = (arrConfigRoute = []) => {
  AddRoutes(RoutesPublic, arrConfigRoute)
}

export const FlowAddPrivateRoute = (arrConfigRoute = []) => {
  AddRoutes(RoutesPrivate, arrConfigRoute)
}

export const FlowAddTestRoute = (arrConfigRoute = []) => {
  AddRoutes(RoutesTest, arrConfigRoute)
}

window._Routes = {
  RoutesPublic,
  RoutesPrivate,
  RoutesTest,
}
