import { HUtils } from "@macashipo/mlib"
import { fnGetConfigTable, KConfigPageTable } from "./fnList"
import {
  FlowAuth,
  FlowBuildColTableFromExtraData,
  FlowTableCell,
  FlowTableExpand,
  FlowTableFilter,
  FlowPage,
  FlowApi,
  FlowMNeedUpdateMLib,
} from "./Flow"

export const Table_BuildCols = ({ fnList, onePage }) => {
  if (onePage) {
    return FlowBuildColTableFromExtraData({
      extraData: onePage._dataExtraData,
      fieldId: "Id",
      sourceList: onePage._dataOptions,
      fnList: fnList,
      showID: fnGetConfigTable(fnList, KConfigPageTable.showID),
      excludeFieldName: [],
      columnClassName: "",
      defaultFormatColumn: null,
      customProps: fnGetConfigTable(fnList, KConfigPageTable.customProps),
      customType: null,
    })
  }
  return []
}

//Summary V3
// const V3 = {
//   Table_BuildCols: Table_BuildCols,
// }
//Summary V5
const V5 = {
  Table_BuildCols: Table_BuildCols, //chua xu dung
  //TableExpand
  TableExpand_CheckExpandItem: FlowTableExpand.FlowCheckExpandItem,
  TableExpand_IsShowExpandItem: FlowTableExpand.FlowIsShowExpandItem,
  TableExpand_ToggleExpandItem: FlowTableExpand.FlowToggleExpandItem,
  //TableCell
  TableCell_KMore: FlowTableCell.KMore,
  TableCell_RenderTitleOfLink: FlowTableCell.FlowRenderTitleOfLink,
  TableCell_GetLinkTarget: FlowTableCell.FlowGetLinkTarget,
  TableCell_GetLink: FlowTableCell.FlowGetLink,
  TableCell_ClickLink: FlowTableCell.FlowActionClickLink,
  TableCell_GetTitleOfColumn: FlowTableCell.FlowGetTitleOfColumn,
  TableCell_RenderStatus: FlowTableCell.FlowRenderStatus,
  Cell_GetStyleCell: FlowTableCell.FlowGetStyleCell,
  Cell_GetHeaderTitle: FlowTableCell.FlowGetHeaderTitle,
  Cell_GetConfigModal: FlowTableCell.FlowGetConfigModal,
  //TableFilter
  TableFilter_IsShowFilter: FlowTableFilter.FlowIsShowFilter,
  TableFilter_IsShowSearch: FlowTableFilter.FlowIsShowSearch,
  //Page
  Page_GetSharedQueryOfPage: FlowPage.FlowGetSharedQueryOfPage,
  //Auth
  Auth_ParseAuthInfo: FlowAuth.FlowParseAuthInfo,
  //Api
  Api_Generic: FlowApi.FlowApiGeneric,
  Api_RefreshCache: FlowApi.FlowApiRefreshCache,
  //Need Update MLib
  M_addItemToArrayOrCreateNewArray:
    FlowMNeedUpdateMLib.addItemToArrayOrCreateNewArray,
}

export default V5
