import React from "react"
import { MyButtonHelpers } from "@macashipo/mlib"

const MyButtonLink = React.lazy(() => import("./Types/link"))
const MyButtonApi = React.lazy(() => import("./Types/api"))
const MyButtonForm = React.lazy(() => import("./Types/form"))
const MyButtonAddNew = React.lazy(() => import("./Types/add_new"))
const MyButtonUpload = React.lazy(() => import("./Types/upload"))
const MyButtonPrint = React.lazy(() => import("./Types/print"))

const List = {
  link: MyButtonLink,
  api: MyButtonApi,
  form: MyButtonForm,
  add_new: MyButtonAddNew,
  upload: MyButtonUpload,
  print: MyButtonPrint,
}
const MyButtonOne = {
  init() {
    MyButtonHelpers.addTypesFromListLazy(List)
  },
}

export default MyButtonOne
