import { MyFormControlHelpers } from "@macashipo/mlib"
import React from "react"

const MFCText = React.lazy(() => import("./Types/text"))
const MFCAlert = React.lazy(() => import("./Types/alert"))
const MFCTextarea = React.lazy(() => import("./Types/textarea"))
const MFCSelect2 = React.lazy(() => import("./Types/select2"))
const MFCDatePicker = React.lazy(() => import("./Types/datepicker"))
const MFCNumberDecimal = React.lazy(() => import("./Types/number_decimal"))
const MFCCurrency = React.lazy(() => import("./Types/currency"))
const MFCPassword = React.lazy(() => import("./Types/password"))
const List = {
  currency: MFCCurrency,
  number_decimal: MFCNumberDecimal,
  date: MFCDatePicker,
  datepicker: MFCDatePicker,
  select2: MFCSelect2,
  textarea: MFCTextarea,
  text: MFCText,
  alert: MFCAlert,
  password: MFCPassword,
}
const MyLayoutOne = {
  init() {
    MyFormControlHelpers.addTypesFromListLazy(List)
  },
}

export default MyLayoutOne
