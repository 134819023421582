import { HFormat, HUtils } from "@macashipo/mlib"

export const KConfigMyUI = {
  type: "type",
  typeMyLayout: "typeMyLayout",
  moreProps: "moreProps",
  customProps: "customProps",
  customData: "customData",
  customDataFormat: "customDataFormat", //customData bang HFormat
  notypemyui: "notypemyui",
  dataIsRow: "dataIsRow", //data is row chu ko phai cell, dung cho table cell
}

export const getMoreProps = (props = {}) => {
  const config = props.config || {}
  let _moreProps = config.moreProps || {}
  if (config[KConfigMyUI.customProps]) {
    for (let k of Object.keys(config[KConfigMyUI.customProps])) {
      _moreProps[k] = HUtils.get(props, config[KConfigMyUI.customProps][k])
    }
  }
  return _moreProps
}
export const getData = (props = {}) => {
  const config = props.config || {}
  const data = props.data
  if (data && typeof data == "object") {
    let _moreData = { ...data }
    if (config[KConfigMyUI.customData]) {
      for (let k of Object.keys(config[KConfigMyUI.customData])) {
        _moreData[k] = HUtils.get(props, config[KConfigMyUI.customData][k])
      }
    }
    if (config[KConfigMyUI.customDataFormat]) {
      for (let k of Object.keys(config[KConfigMyUI.customDataFormat])) {
        let _configFormat = config[KConfigMyUI.customDataFormat][k]
        if (_configFormat.type && HFormat.Types[_configFormat.type]) {
          let _value = _configFormat.value
          if (typeof _value == "string" && _value.indexOf("[") > -1) {
            _value = HUtils.getTextWithData(_configFormat.value, data)
          }
          _moreData[k] = HFormat.format(_value, _configFormat)
        }
      }
    }
    // console.warn("_moreData", _moreData)
    return _moreData
  }

  // console.warn("data", data)
  return data
}
export const getStyle = (props = {}) => {
  const config = props.config || {}
  const data = getData(props)
  const style = config.style || {}
  let _style = { ...style }
  // console.warn("getStyle", _style)
  if (data && style && Object.keys(style).length > 0) {
    for (let k of Object.keys(style)) {
      if (_style[k] && _style[k].startsWith("[")) {
        let _fieldName = _style[k].replace("[", "").replace("]", "")
        if (data.hasOwnProperty(_fieldName)) {
          _style[k] = data[_fieldName]
        }
      }
    }
  }
  return _style
}
export const getTypeMyLayout = (config = {}) => {
  return config[KConfigMyUI.typeMyLayout] || "div"
}
export const getTypeMyUI = (config = {}) => {
  return config[KConfigMyUI.type] || "notypemyui"
}
