import * as types from "./Login.constants"

export const fetchLoginPending = () => {
  return {
    type: types.FETCH_LOGIN_PENDING,
  }
}

export const fetchLoginSuccess = data => {
  return {
    type: types.FETCH_LOGIN_SUCCESS,
    res: data,
  }
}

export const fetchLoginError = error => {
  return {
    type: types.FETCH_LOGIN_ERROR,
    error: error,
  }
}
