import HConstant from "./constant"
import { initApi } from "./api"
import { initAuth, getUserID, getUserName } from "./auth"
import { initLink, push } from "./link"
import { initI18n } from "./i18n"
import C from "./c"
import { fnListUI } from "src/helpers/fnList"
import { initLocalStorage } from "./localStorage"
import HServiceWorker from "./serviceWorker"
import {
  addArray2Array,
  // isArray,
  isFunction,
  mergeObjIfExist,
  runFuntion,
} from "./utils"
import { HModules, HCF, HLink } from "@macashipo/mlib"
const configApi: IConfigApi = {
  baseUrl: "",
  timeout: 120000,
}

const configRoutes: IRouteConfig = {
  routesPublic: [],
  routesPrivate: [],
}

const configLink: IConfigLink = {
  usingHash: false,
}

const configSidebar = {
  list: [],
}
const Config: IHConfig = {
  appInfo: {
    title: "AllianceITSC",
    appName: "allianceitsc",
    home: "/home",
  },
  configApp: null,
  configAppExt: null,
  configI18n: {},
  configLink: configLink,
  configApi: configApi,
  configAuth: {},
  configModules: {},
  configRoutes: configRoutes,
  configSidebar: configSidebar,
  extAfterLogin: [],
  extSettingParts: [],
  headerComponent: null,
  initConfigAppExt() {
    if (window["_ConfigApp"]) {
      Config.configAppExt = window["_ConfigApp"]
      HCF.setCFMultiObj(Config.configAppExt)
      mergeObjIfExist(Config.appInfo, window["_ConfigApp"])
      //
      if (
        Config.configAppExt &&
        Config.configAppExt.baseUrl &&
        Config.configApi
      ) {
        Config.configApi.baseUrl = Config.configAppExt.baseUrl
      }
    }
  },
  initConfigApp(configApp: IConfigApp = {}) {
    console.log("initConfigApp:", configApp)
    for (let k of Object.keys(configApp)) {
      if (
        k.startsWith("config") ||
        k === "fnList" ||
        k === "appInfo" ||
        k === "UIAppConfig"
      ) {
        HCF.setCFMultiObj(configApp[k])
        mergeObjIfExist(Config[k], configApp[k])
      }
    }
    //
    HCF.setCF("addPublicRoutes", Config.addPublicRoutes)
    //
    Config.configApp = configApp
    Config.initConfigAppExt()
    C.initC(Config)
    //other helpers
    initLink(Config.configLink)
    initI18n(Config.configI18n)
    initLocalStorage({})
    initApi({ ...Config.configApi, appName: Config.appInfo.appName })
    initAuth({
      ...configApp.configAuth,
      afterLogin: () => {
        // console.warn("afterLogin")
        if (configApp.configAuth && configApp.configAuth.afterLogin) {
          configApp.configAuth.afterLogin()
        } else {
          //go to home - shouldnot push -> create loop
          console.warn("need push to home")
          // push(Config.appInfo.home)
        }
        if (Config.extAfterLogin && Config.extAfterLogin.length > 0) {
          for (let fn of Config.extAfterLogin) {
            if (isFunction(fn)) {
              ;(fn as () => void)()
            }
          }
        }
      },
      afterLogout: () => {
        console.warn("afterLogout")
        if (configApp.configAuth && configApp.configAuth.afterLogout) {
          configApp.configAuth.afterLogout()
        } else {
          HLink.push("/")
        }
      },
    })

    //modules
    HModules.initModules(
      configApp.modules as any[],
      Config.configModules,
      Config
    )
    // if (configApp.modules && configApp.modules.length > 0) {
    //   for (let i = 0; i < configApp.modules.length; i++) {
    //     runFuntion(
    //       configApp.modules[i],
    //       HConstant.Modules.FnList.runAfterInit,
    //       [Config]
    //     )
    //   }
    // }
    runFuntion(configApp.fnList, HConstant.ConfigApp.FnList.onAfterInit, [
      Config,
    ])

    HServiceWorker.init()
  },
  fnList: {},
  UIAppConfig: {},
  getConfig(key: string) {
    if (Config.configAppExt && Config.configAppExt.hasOwnProperty(key)) {
      return Config.configAppExt[key]
    }
    if (Config.UIAppConfig && Config.UIAppConfig.hasOwnProperty(key)) {
      return Config.UIAppConfig[key]
    }
    return Config[key]
  },
  getPublicRoutes() {
    return Config.configRoutes.routesPublic
  },
  getPrivateRoutes() {
    return Config.configRoutes.routesPrivate
  },
  addPublicRoutes(list: IServer.IConfigPage[]) {
    addArray2Array(Config.configRoutes.routesPublic, list)
    fnListUI.fnForceUpdateRoutes()
  },
  addPrivateRoutes(list: IServer.IConfigPage[]) {
    addArray2Array(Config.configRoutes.routesPrivate, list)
  },
  addSidebarItems(list) {
    addArray2Array(Config.configSidebar.list, list)
  },
  registerAfterLogin(fn: never) {
    Config.extAfterLogin.push(fn)
  },
  registerExtSettingParts(fn: never) {
    Config.extSettingParts.push(fn)
  },
  registerHeaderTitle(component) {
    Config.headerComponent = component
  },
  changeTitle(title?: any, headerTitle?: any) {
    window.document.title = `${title != null ? title + " - " : ""}${
      Config.appInfo.title
    }`
    if (headerTitle != null && Config.fnList["updateHeaderTitle"]) {
      Config.fnList["updateHeaderTitle"](headerTitle)
    }
  },
  goToHome() {
    push(Config.appInfo.home)
  },
  getAuthUserName() {
    return getUserName()
  },
  getAuthUserId() {
    return getUserID()
  },
  getAppName() {
    return Config.appInfo.appName
  },
  addBodyClass(className: string) {
    document.body.classList.add(className)
  },
  removeBodyClass(className: string) {
    document.body.classList.remove(className)
  },
  getConfigFooter() {
    let _footer = '<a href="https://allianceitsc.com">Allianceitsc</a> © 2021.'
    let _configFooter = {
      show: true,
      footer: _footer,
    }
    if (Config.configAppExt) {
      if (Config.configAppExt.hasOwnProperty("footer")) {
        _configFooter.footer = Config.configAppExt["footer"]
      }
    }
    return _configFooter
  },
}
window["HConfig"] = Config
export default Config
