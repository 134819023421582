import MyIconOne from "./MyIcon"

interface IModuleIconHiOutline extends IModuleOne {
  runAfterInit: () => void
}
const ModuleIconHiOutline: IModuleIconHiOutline = {
  runAfterInit: () => {
    MyIconOne.init()
  },
}

export default ModuleIconHiOutline
