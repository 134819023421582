import React from "react"
import { MyUI, MyLayout } from "@macashipo/mlib"
import { getData, getTypeMyLayout, getTypeMyUI, getStyle } from "./_helpers"
class MyUI_Data_Array extends React.Component {
  render() {
    const { data } = this.props
    const config = this.props.config || {}
    let _configChild = config.configChild || {}
    // console.error("data_obj", this.props)
    if (data != null) {
      return (
        <MyLayout
          type={getTypeMyLayout(config)}
          className={config.className}
          style={getStyle(this.props)}
          {...config.moreProps}
        >
          <MyUI
            {...this.props}
            type={getTypeMyUI(_configChild)}
            data={getData(this.props)}
            config={_configChild}
            {..._configChild.moreProps}
          />
        </MyLayout>
      )
    }
    return <div></div>
  }
}

export default MyUI_Data_Array
