import React, { Fragment, lazy, Suspense } from "react"

const Dashboards = lazy(() => import("../CDashboard"))

const AppMain = props => {
  return (
    <Fragment>
      {/* Dashboards */}

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <h6 className="mt-3">
                Please wait while we load all the components
                <small>
                  Because this is a demonstration, we load at once all the
                  components
                </small>
              </h6>
            </div>
          </div>
        }
      >
        <Dashboards>{props.children}</Dashboards>
      </Suspense>
    </Fragment>
  )
}

export default AppMain
