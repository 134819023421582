import { HJson, HCF, HLocalStorage, HUtils, HEE } from "@macashipo/mlib"
import K, { KStorage } from "src/helpers/K"

export const KAuth = {
  fnUpdateAuthInfo: "fnUpdateAuthInfo",
  eeUpdateAuthInfo: "eeUpdateAuthInfo",
}
export const FlowParseAuthInfo = authInfo => {
  console.warn("parse auth info", authInfo)
  if (authInfo) {
    if (authInfo.configs) {
      let _configs = HJson.getObj(authInfo.configs, {})
      if (_configs && _configs.home_url) {
        HCF.setCF(K.KConfigApp.home_url, _configs.home_url)
        HLocalStorage.saveString(KStorage.HomeURL, _configs.home_url)
      }
    }
  }
}

export const FlowUpdateAuthInfo = ({ name, avatar } = {}) => {
  let _authInfo = HLocalStorage.getObj(KStorage.AuthInfo)
  if (_authInfo) {
    if (name && _authInfo.name != name) {
      _authInfo.name = name
    }
    if (avatar && _authInfo.avatar_url != avatar) {
      _authInfo.avatar_url = avatar
    }
    HLocalStorage.saveObj(KStorage.AuthInfo, _authInfo)
    HUtils.runFuntion(HCF.CF, KAuth.fnUpdateAuthInfo, [_authInfo])
    HEE.emit(KAuth.eeUpdateAuthInfo, _authInfo)
  }
}
