import React from "react"
import { MyUIHelpers } from "@macashipo/mlib"
import MUI_html from "./Types/html"
import MUI_data_obj from "./Types/data_obj"
import MUI_data_array from "./Types/data_array"
import MUI_data_tag from "./Types/data_tag"

const ListNoLazy = {
  html: MUI_html,
  data_obj: MUI_data_obj,
  data_array: MUI_data_array,
  data_tag: MUI_data_tag,
}
const MyUIOne = {
  init() {
    MyUIHelpers.addTypesFromListWithoutLazy(ListNoLazy)
  },
}

export default MyUIOne
