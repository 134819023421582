import React from "react"
import { Button, Form } from "reactstrap"
import { HValid, HUtils, HIs, HJson } from "@macashipo/mlib"
import { fnListMyForm, kresetFormControl } from "src/helpers/fnList"
import MyFormControl from "./MyFormControl"
import { KMoreOfForm } from "src/helpers/Flows/Form"
import i18next from "i18next"
class MyForm extends React.Component<IPropsMyForm> {
  currentValues = {}
  toucheds: string[] = [] //danh sach field da tac dong
  errorsValid = {} //danh sach loi, check valid
  relativeControls: any = {} //{a:["b","c"]}//field a -> anh huong toi b c
  effectControls: any = {}
  controlsByFieldName = {}
  controlsOptsByFieldName: any = {}
  fnListFromWrap: any = {}
  fnListFromExtControl: any = {}
  constructor(props) {
    super(props)
    this._buildRelativeControls()
    this._initCurrentValuesByDefaultValue()
  }
  closeMyModal = () => {
    const { inMyModal } = this.props
    if (inMyModal) {
      inMyModal.hide()
    }
  }
  _initCurrentValuesByDefaultValue = () => {
    const { configForm } = this.props
    if (configForm.defaultValues) {
      for (let k of Object.keys(configForm.defaultValues)) {
        this.currentValues[k] = configForm.defaultValues[k]
      }
    }
  }
  _buildRelativeControls = () => {
    const { configForm } = this.props
    if (configForm && configForm.controls) {
      for (let i = 0; i < configForm.controls.length; i++) {
        let _control = configForm.controls[i]
        let _fieldName = _control.fieldName || ""
        let _more = _control.more
        if (_control.groups?.length && !_fieldName) {
          const groups = _control.groups
          for (let j = 0; j < groups.length; j++) {
            const _controlInGrp = groups[j]
            const _moreCtlInGrp = _controlInGrp.more
            const _fieldNameCtlInGrp = _controlInGrp.fieldName
            if (
              _moreCtlInGrp &&
              _moreCtlInGrp.relativeTo &&
              _moreCtlInGrp.relativeTo.length > 0
            ) {
              if (this.relativeControls[_fieldNameCtlInGrp] === undefined) {
                this.relativeControls[_fieldNameCtlInGrp] = []
              }
              this.relativeControls[_fieldNameCtlInGrp] = this.relativeControls[
                _fieldNameCtlInGrp
              ].concat(_moreCtlInGrp.relativeTo)
            }
            if (_moreCtlInGrp && _moreCtlInGrp.conditionShow) {
              let _keys = Object.keys(_moreCtlInGrp.conditionShow)
              if (!_keys.includes("isCondition")) {
                for (let k of _keys) {
                  if (this.relativeControls[k] === undefined) {
                    this.relativeControls[k] = []
                  }
                  if (
                    this.relativeControls[k].indexOf(_fieldNameCtlInGrp) === -1
                  ) {
                    this.relativeControls[k].push(_fieldNameCtlInGrp)
                  }
                }
              } else {
                // Khi dieu kien show của 1 control la 1 dieu kien khong phai 1 gia tri cu the
                const conditionShow = _moreCtlInGrp.conditionShow
                if (
                  this.relativeControls[conditionShow.fieldRelative] ===
                  undefined
                ) {
                  this.relativeControls[conditionShow.fieldRelative] = []
                }
                if (
                  this.relativeControls[conditionShow.fieldRelative].indexOf(
                    _fieldNameCtlInGrp
                  ) === -1
                ) {
                  this.relativeControls[conditionShow.fieldRelative].push(
                    _fieldNameCtlInGrp
                  )
                }
              }
            }
            if (_more && _more.effectedBy && _more.effectedBy.length > 0) {
              for (let k of _more.effectedBy) {
                if (this.effectControls[k] === undefined) {
                  this.effectControls[k] = []
                }
                if (this.effectControls[k].indexOf(_fieldNameCtlInGrp) === -1) {
                  this.effectControls[k].push(_fieldNameCtlInGrp)
                }
              }
            }
          }
        } else {
          if (_more && _more.relativeTo && _more.relativeTo.length > 0) {
            if (this.relativeControls[_fieldName] === undefined) {
              this.relativeControls[_fieldName] = []
            }
            this.relativeControls[_fieldName] = this.relativeControls[
              _fieldName
            ].concat(_more.relativeTo)
          }
          if (_more && _more.conditionShow) {
            let _keys = Object.keys(_more.conditionShow)
            if (!_keys.includes("isCondition")) {
              for (let k of _keys) {
                if (this.relativeControls[k] === undefined) {
                  this.relativeControls[k] = []
                }
                if (this.relativeControls[k].indexOf(_fieldName) === -1) {
                  this.relativeControls[k].push(_fieldName)
                }
              }
            } else {
              // Khi dieu kien show của 1 control la 1 dieu kien khong phai 1 gia tri cu the
              const conditionShow = _more.conditionShow
              const fieldRelative = conditionShow?.fieldRelative
              // Danh cho truong hop phu thuoc 1 control trong 1 group
              if (fieldRelative.indexOf(".") > -1) {
                //Chi lay field cua cha vi ben trong thay doi thi se thay doi luon ca group va se update control phu thuoc
                let _fieldParent = fieldRelative.split(".")?.[0]
                if (this.relativeControls[_fieldParent] === undefined) {
                  this.relativeControls[_fieldParent] = []
                }
                if (
                  this.relativeControls[_fieldParent].indexOf(_fieldName) === -1
                ) {
                  this.relativeControls[_fieldParent].push(_fieldName)
                }
              } else {
                if (this.relativeControls[fieldRelative] === undefined) {
                  this.relativeControls[fieldRelative] = []
                }
                if (
                  this.relativeControls[fieldRelative].indexOf(_fieldName) ===
                  -1
                ) {
                  this.relativeControls[fieldRelative].push(_fieldName)
                }
              }
            }
          }
          if (_more && _more.effectedBy && _more.effectedBy.length > 0) {
            for (let k of _more.effectedBy) {
              if (this.effectControls[k] === undefined) {
                this.effectControls[k] = []
              }
              if (this.effectControls[k].indexOf(_fieldName) === -1) {
                this.effectControls[k].push(_fieldName)
              }
            }
          }
        }
      }
    }
    console.warn("this.relativeControls", this.relativeControls)
  }
  _getMoreOfForm = () => {
    const { configForm } = this.props
    let _more = {}
    if (configForm.more) {
      if (HIs.isJsonString(configForm.more)) {
        _more = HJson.getObj(configForm.more)
      } else {
        _more = configForm.more
      }
    }
    return _more
  }
  _getMoreWithKey = (key = "") => {
    let _moreOfForm = this._getMoreOfForm()
    if (key && _moreOfForm.hasOwnProperty(key)) {
      return _moreOfForm[key]
    }
  }
  _onTouch = (fieldName, newValue) => {
    if (this.toucheds.indexOf(fieldName) === -1) {
      this.toucheds.push(fieldName)
    }
    this.currentValues[fieldName] = newValue
    if (this.relativeControls[fieldName]) {
      for (let k of this.relativeControls[fieldName]) {
        console.warn("need update field:", k, this.fnListFromExtControl[k])
        if (
          this.fnListFromExtControl[k] &&
          this.fnListFromExtControl[k].forceUpdate
        ) {
          this.fnListFromExtControl[k].forceUpdate()
        }
      }
    }
    if (this.effectControls[fieldName]) {
      for (let k of this.effectControls[fieldName]) {
        console.warn("need update effect:", k, this.fnListFromExtControl[k])
        if (
          this.fnListFromExtControl[k] &&
          this.fnListFromExtControl[k].updateValueEffect
        ) {
          this.fnListFromExtControl[k].updateValueEffect()
        }
      }
    }
  }
  _onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const { fnList, configForm, inMyModal } = this.props
    event.preventDefault()
    console.warn("Submit", this.currentValues)
    if (fnList && fnList.onSubmitForm) {
      const result = fnList.onSubmitForm(this.currentValues, {
        defaultValues: configForm.defaultValues,
        errorsValid: this.errorsValid,
        relativeControls: this.relativeControls,
        toucheds: this.toucheds,
        myForm: this,
      })
      if (result) {
        result
          .then((rs: any) => {
            console.warn("promise myform rs:", rs)
            if (configForm.closeMyModalAfterSubmit === true && inMyModal) {
              inMyModal.hide()
            }
          })
          .catch(err => {
            console.warn("err:", err)
          })
      }
    }
  }
  getCurrentValues = () => {
    return this.currentValues
  }
  getSourceList = () => {
    const { fnList } = this.props
    if (fnList && fnList.getSourceList) {
      return fnList.getSourceList()
    }
    return {}
  }
  getConfigControlByFieldName = (fieldName: string) => {
    const { configForm } = this.props
    if (this.controlsByFieldName[fieldName]) {
      return this.controlsByFieldName[fieldName]
    }
    if (configForm && configForm.controls && configForm.controls.length > 0) {
      for (let i = 0; i < configForm.controls.length; i++) {
        let _control = configForm.controls[i]
        if (_control.fieldName === fieldName) {
          this.controlsByFieldName[fieldName] = _control
          return _control
        }
      }
    }
  }
  getConfigInMoreOfControl = (
    fieldName: string,
    key: string,
    configControl?: any
  ) => {
    let _configControl: any =
      configControl || this.getConfigControlByFieldName(fieldName)
    if (_configControl && _configControl.more && _configControl.more[key]) {
      return _configControl.more[key]
    }
  }
  getRulesOfControl = (fieldName: string) => {
    return this.getConfigInMoreOfControl(fieldName, "rules")
  }
  checkValidAll = () => {
    const { configForm } = this.props
    let _result: any = true
    if (configForm && configForm.controls) {
      for (let i = 0; i < configForm.controls.length; i++) {
        let _control = configForm.controls[i]
        let _resultOfControl = this.checkValidOfField(
          _control.fieldName,
          _control
        )
        if (_resultOfControl !== true) {
          if (_result === true) {
            _result = {}
          }
          if (_control.fieldName) {
            _result[_control.fieldName] = _resultOfControl
          }
        }
      }
    }
    console.warn("checkValidAll:", _result)
    if (_result !== true) {
      this.errorsValid = _result
    } else {
      this.errorsValid = {}
    }
    this.forceUpdate()
    return _result
  }
  checkValidOfField = (fieldName: string = "", configControl?: any) => {
    let _configControl: any =
      configControl || this.getConfigControlByFieldName(fieldName)
    let _result = true
    if (_configControl) {
      let _rules = this.getRulesOfControl(fieldName)
      let _value = this.currentValues[fieldName]
      if (_rules) {
        _result = HValid.validAllRulesOfValue(_rules, _value)
        console.warn("checkValidOfField:", fieldName, _rules, _value, _result)
      }
    }
    return _result
  }
  _renderBtnSubmit = () => {
    let _configBtnSubmit = this._getMoreWithKey(KMoreOfForm.btnSubmit) || {}
    return (
      <Button type="submit" color="primary me-2">
        {_configBtnSubmit.title || i18next.t("Save")}
      </Button>
    )
  }
  _renderBtnReset = () => {
    const { configForm } = this.props
    let _configBtnReset = this._getMoreWithKey(KMoreOfForm.btnReset) || {}
    if (_configBtnReset.hide == true) {
      return null
    }
    return (
      <Button
        type="reset"
        color="secondary"
        onClick={() => {
          this.currentValues = { ...configForm.defaultValues }
          if (configForm && configForm.controls) {
            for (let i = 0; i < configForm.controls.length; i++) {
              let _control = configForm.controls[i]
              let _fieldName = _control.fieldName || ""
              console.warn("fnListFromExtControl", this.fnListFromExtControl)
              fnListMyForm[kresetFormControl](
                this.fnListFromExtControl[_fieldName],
                []
              )
            }
          }
          // this.forceUpdate()
        }}
      >
        {_configBtnReset.title || i18next.t("Reset")}
      </Button>
    )
  }
  _renderFooter = () => {
    const { configForm } = this.props
    if (this.props.renderFooter) {
      return this.props.renderFooter(this)
    }
    return (
      <div className="a-footer">
        {this._renderBtnSubmit()}
        {this._renderBtnReset()}
      </div>
    )
  }
  render() {
    const { configForm, style, className, fnList, autocomplete } = this.props
    console.warn("render MyForm:", configForm, this.props)
    return (
      <Form
        onSubmit={this._onSubmit}
        style={style}
        className={className}
        autocomplete={autocomplete}
      >
        <div>
          {configForm &&
            configForm.controls &&
            configForm.controls?.map((v, i) => {
              return (
                <MyFormControl
                  key={i}
                  type={v.type}
                  configFormControl={{
                    ...v,
                    defaultValues: configForm.defaultValues,
                    formErrorsValid: this.errorsValid,
                  }}
                  fnList={{
                    ...fnList,
                    initWrapControl: (fieldName: string, fnFromWrap: any) => {
                      if (this.fnListFromWrap[fieldName] === undefined) {
                        this.fnListFromWrap[fieldName] = fnFromWrap
                      }
                      // console.warn(
                      //   "initWrapControl:",
                      //   fieldName,
                      //   fnFromWrap,
                      //   this.fnListFromWrap
                      // )
                    },
                    initExtControl: (
                      fieldName: string,
                      fnFromExtControl: any
                    ) => {
                      if (this.fnListFromExtControl[fieldName] === undefined) {
                        this.fnListFromExtControl[fieldName] = fnFromExtControl
                      }
                      // console.warn(
                      //   "initExtControl:",
                      //   fieldName,
                      //   fnFromExtControl,
                      //   this.fnListFromExtControl
                      // )
                    },
                    addFnListFromControl: (fnName, fn, fieldName) => {
                      if (!this.fnListFromExtControl[fieldName]) {
                        this.fnListFromExtControl[fieldName] = {}
                      }
                      if (this.fnListFromExtControl[fieldName]) {
                        this.fnListFromExtControl[fieldName][fnName] = fn
                      }
                    },
                    getSourceList: this.getSourceList,
                    getCurrentValues: () => {
                      return this.currentValues
                    },
                    getDefaultValues: () => {
                      return this.props.configForm.defaultValues || {} //tranh truong hop defaultValues la null
                    },
                    onUpdateValue: ({ fieldName, newValue }) => {
                      console.warn("onUpdateValue:", fieldName, newValue)
                      HUtils.runFuntion(
                        this.props.fnList,
                        "afterUpdateValueControl",
                        [
                          {
                            fieldName,
                            newValue,
                            control:
                              this.getConfigControlByFieldName(fieldName),
                          },
                        ]
                      )
                      this._onTouch(fieldName, newValue)
                    },
                    onJustUpdateValueMultiCurrentValuesForm: (list: any) => {
                      let _keys = Object.keys(list)
                      for (let k of _keys) {
                        this.currentValues[k] = list[k]
                      }
                    },
                    onUpdateControlWithNewValue: (fieldName, newValue) => {
                      HUtils.runFuntion(
                        this.props.fnList,
                        "afterUpdateValueControl",
                        [
                          {
                            fieldName,
                            newValue,
                            control:
                              this.getConfigControlByFieldName(fieldName),
                          },
                        ]
                      )
                      this._onTouch(fieldName, newValue)
                      if (
                        this.fnListFromExtControl[fieldName] &&
                        this.fnListFromExtControl[fieldName].updateValue
                      ) {
                        this.fnListFromExtControl[fieldName].updateValue(
                          newValue
                        )
                      }
                    },
                    onUpdateValueMulti: (list: any) => {
                      console.warn("onUpdateValueMulti:", list)
                      let _keys = Object.keys(list)
                      if (_keys && _keys.length > 0) {
                        for (let k of _keys) {
                          HUtils.runFuntion(
                            this.props.fnList,
                            "afterUpdateValueControl",
                            [
                              {
                                fieldName: k,
                                newValue: list[k],
                                control: this.getConfigControlByFieldName(k),
                              },
                            ]
                          )
                          this._onTouch(k, list[k])
                        }
                      }
                    },
                    registerControl: (fieldName: string, opts: any = {}) => {
                      this.controlsOptsByFieldName[fieldName] = {
                        component: opts.component,
                        forceUpdate: opts.forceUpdate,
                      }
                    },
                    onTouch: ({ fieldName }: { fieldName: string }) => {
                      if (this.toucheds.indexOf(fieldName) === -1) {
                        this.toucheds.push(fieldName)
                      }
                    },
                    fnGetTypeWrapControl: () => {
                      return this._getMoreWithKey(KMoreOfForm.typeWrapControl)
                    },
                  }}
                />
              )
            })}

          {/* <FormGroup>
          <Label for="exampleEmail">Email</Label>
          <Input
            type="email"
            name="email"
            id="exampleEmail"
            placeholder="with a placeholder"
          />
        </FormGroup>
        <FormGroup>
          <Label for="examplePassword">Password</Label>
          <Input
            type="password"
            name="password"
            id="examplePassword"
            placeholder="password placeholder"
          />
        </FormGroup>
        <FormGroup>
          <Label for="exampleSelect">Select</Label>
          <Input type="select" name="select" id="exampleSelect">
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="exampleSelectMulti">Select Multiple</Label>
          <Input
            type="select"
            name="selectMulti"
            id="exampleSelectMulti"
            multiple
          >
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="exampleText">Text Area</Label>
          <Input type="textarea" name="text" id="exampleText" />
        </FormGroup>
        <FormGroup>
          <Label for="exampleFile">File</Label>
          <Input type="file" name="file" id="exampleFile" />
          <FormText color="muted">
            This is some placeholder block-level help text for the above input.
            It's a bit lighter and easily wraps to a new line.
          </FormText>
        </FormGroup>
        <FormGroup tag="fieldset">
          <legend>Radio Buttons</legend>
          <FormGroup check>
            <Label check>
              <Input type="radio" name="radio1" /> Option one is this and
              that—be sure to include why it's great
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input type="radio" name="radio1" /> Option two can be something
              else and selecting it will deselect option one
            </Label>
          </FormGroup>
          <FormGroup check disabled>
            <Label check>
              <Input type="radio" name="radio1" disabled /> Option three is
              disabled
            </Label>
          </FormGroup>
        </FormGroup>
        <FormGroup check>
          <Label check>
            <Input type="checkbox" /> Check me out
          </Label>
        </FormGroup> */}
        </div>
        {this._renderFooter()}
      </Form>
    )
  }
}
export default MyForm
