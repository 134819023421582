import MyUIOne from "./MyUI"
import MyLayoutOne from "./MyLayout"
import MyPageOne from "./MyPage"
interface IModuleCorePage extends IModuleOne {
  runAfterInit: () => void
}
const ModuleCorePage: IModuleCorePage = {
  runAfterInit: () => {
    MyLayoutOne.init()
    MyUIOne.init()
    MyPageOne.init()
  },
}

export default ModuleCorePage
