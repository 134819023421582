import React from "react"
import { HUtils } from "src/core/helpers"
class MyUI_HTML extends React.Component<any, any> {
  render() {
    const { html, className, style, data, onClick } = this.props
    // console.warn("MyUI_HTML:",this.props);
    if (data && html && typeof data == "object") {
      let _html = HUtils.getTextWithData(html, data)
      // console.warn("MyUI_HTML:",_html);
      return (
        <div
          className={className}
          style={style}
          onClick={onClick}
          dangerouslySetInnerHTML={{ __html: _html }}
        />
      )
    } else if (html) {
      return (
        <div
          className={className}
          style={style}
          onClick={onClick}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      )
    }
    return <div></div>
  }
}

export default MyUI_HTML
