import { fnListMyPage } from "src/helpers/fnList"
const kfrConfigExpandByKey = "_configExpandByKey"
const kUI_DefaultShowExpand = "UI_DefaultShowExpand"
const FlowGetKeyOfExpandItem = configItemExpand => {
  if (configItemExpand) {
    return configItemExpand.key || configItemExpand.screenCode
  }
}
// const FlowIsConfigItemOneNotList = configItemExpand => {
//   if (
//     configItemExpand &&
//     configItemExpand.controller &&
//     configItemExpand.screenCode
//   ) {
//     return true
//   }
//   return false
// }
export const FlowCheckExpandItem = ({
  configItemExpand,
  row,
  fnListOfMyTableCell,
  prefix = "",
} = {}) => {
  let _onePage = fnListMyPage.fnGetOnePage(fnListOfMyTableCell)
  // console.log("_onePage:", fnListOfMyTableCell, _onePage)
  let _configExpandOfRow = FlowConfigExpandOfRow({
    row,
    onePage: _onePage,
    fnListOfMyTableCell,
  })
  let _needUpdateConfigExpandOfRow = false
  if (row && configItemExpand) {
    let _firstCheck = false
    let _needForceUpdateDetail = false
    const item = configItemExpand
    let _keyOfExpand = FlowGetKeyOfExpandItem(configItemExpand)
    if (!_configExpandOfRow) {
      _configExpandOfRow = {}
      _needUpdateConfigExpandOfRow = true
      _firstCheck = true
    }
    if (_configExpandOfRow) {
      if (_keyOfExpand && !_configExpandOfRow[`${prefix}${_keyOfExpand}`]) {
        let _isShow = row[kUI_DefaultShowExpand] === true ? true : false
        _configExpandOfRow[`${prefix}${_keyOfExpand}`] = {
          ...item,
          _prefix: prefix,
          _isShow: _isShow,
        }
        if (_isShow) {
          _needForceUpdateDetail = true
        }
      } else if (item.list && item.list.length > 0) {
        for (let i = 0; i < item.list.length; i++) {
          let _itemInList = item.list[i]
          let _keyOfExpandInList = FlowGetKeyOfExpandItem(_itemInList)
          if (
            _keyOfExpandInList &&
            !_configExpandOfRow[`${prefix}${_keyOfExpandInList}`]
          ) {
            let _isShow = row[kUI_DefaultShowExpand] === true ? true : false
            _configExpandOfRow[`${prefix}${_keyOfExpandInList}`] = {
              ..._itemInList,
              _prefix: prefix,
              _isShow: _isShow,
            }
            if (_isShow) {
              _needForceUpdateDetail = true
            }
          }
        }
      }
    }
    if (_needUpdateConfigExpandOfRow) {
      if (_onePage && !_onePage["_configRowById"]) {
        _onePage["_configRowById"] = {}
      }
      if (
        row &&
        row.Id &&
        _onePage["_configRowById"] &&
        !_onePage["_configRowById"][row.Id]
      ) {
        _onePage["_configRowById"][row.Id] = {}
      }
      if (_onePage["_configRowById"][row.Id]) {
        _onePage["_configRowById"][row.Id][kfrConfigExpandByKey] =
          _configExpandOfRow
      }
      row[kfrConfigExpandByKey] = _configExpandOfRow
    }
    if (
      _firstCheck &&
      _needForceUpdateDetail &&
      row &&
      row["_fnForceUpdateDetail"]
    ) {
      console.warn("forceUpdate detail")
      row["_fnForceUpdateDetail"]()
    }
  }
}
export const FlowGetAllExpandItemOfRow = ({ row, onePage } = {}) => {
  let _allExpand = []
  if (row) {
    if (row[kfrConfigExpandByKey]) {
      let _configExpand = row[kfrConfigExpandByKey]
      for (let k of Object.keys(_configExpand)) {
        _allExpand.push(_configExpand[k])
      }
    } else {
      if (
        row.Id &&
        onePage &&
        onePage["_configRowById"] &&
        onePage["_configRowById"][row.Id] &&
        onePage["_configRowById"][row.Id][kfrConfigExpandByKey]
      ) {
        row[kfrConfigExpandByKey] =
          onePage["_configRowById"][row.Id][kfrConfigExpandByKey]
        let _configExpand = row[kfrConfigExpandByKey]
        for (let k of Object.keys(_configExpand)) {
          _allExpand.push(_configExpand[k])
        }
      }
    }
  }
  // console.log("_allExpand:", _allExpand)
  return _allExpand
}
export const FlowConfigExpandOfRow = ({
  row,
  onePage,
  fnListOfMyTableCell,
} = {}) => {
  let _configExpand = row[kfrConfigExpandByKey]
  let _onePage = onePage
  if (!_onePage && fnListOfMyTableCell) {
    _onePage = fnListMyPage.fnGetOnePage(fnListOfMyTableCell)
  }
  if (_configExpand == null) {
    if (
      row &&
      row.Id &&
      _onePage &&
      _onePage["_configRowById"] &&
      _onePage["_configRowById"][row.Id] &&
      _onePage["_configRowById"][row.Id][kfrConfigExpandByKey]
    ) {
      row[kfrConfigExpandByKey] =
        _onePage["_configRowById"][row.Id][kfrConfigExpandByKey]
      _configExpand = row[kfrConfigExpandByKey]
    }
  }
  return _configExpand
}
export const FlowIsShowExpandItem = ({
  configItemExpand,
  row,
  fnListOfMyTableCell,
  prefix = "",
} = {}) => {
  //init check first
  // console.log("FlowIsShowExpandItem", fnListOfMyTableCell)
  FlowCheckExpandItem({ configItemExpand, row, fnListOfMyTableCell, prefix })
  let _configExpandOfRow = FlowConfigExpandOfRow({ row, fnListOfMyTableCell })
  // console.log("_configExpandOfRow:", _configExpandOfRow)
  if (_configExpandOfRow && configItemExpand) {
    const item = configItemExpand
    if (_configExpandOfRow) {
      let _keyOfExpand = FlowGetKeyOfExpandItem(configItemExpand)
      if (_keyOfExpand && _configExpandOfRow[`${prefix}${_keyOfExpand}`]) {
        return _configExpandOfRow[`${prefix}${_keyOfExpand}`]._isShow === true
          ? true
          : false
      } else if (item.list && item.list.length > 0) {
        for (let i = 0; i < item.list.length; i++) {
          let _itemInList = item.list[i]
          let _keyOfExpandInList = FlowGetKeyOfExpandItem(_itemInList)
          if (
            _keyOfExpandInList &&
            _configExpandOfRow[`${prefix}${_keyOfExpandInList}`]
          ) {
            if (
              _configExpandOfRow[`${prefix}${_keyOfExpandInList}`]._isShow ===
              false
            ) {
              return false
            }
          }
        }
        return true
      }
    }
  }
  return false
}
export const FlowToggleExpandItem = ({
  configItemExpand,
  row,
  fnListOfMyTableCell,
  prefix = "",
  forceUpdateCell,
} = {}) => {
  let _configExpandOfRow = FlowConfigExpandOfRow({ row, fnListOfMyTableCell })
  if (row && configItemExpand) {
    const item = configItemExpand
    if (_configExpandOfRow) {
      let _keyOfExpand = FlowGetKeyOfExpandItem(configItemExpand)
      if (_keyOfExpand) {
        if (_configExpandOfRow[`${prefix}${_keyOfExpand}`]) {
          _configExpandOfRow[`${prefix}${_keyOfExpand}`]._isShow =
            _configExpandOfRow[`${prefix}${_keyOfExpand}`]._isShow === true
              ? false
              : true
        } else {
          console.warn("Missing config expand in row", row)
          FlowCheckExpandItem({
            configItemExpand,
            row,
            fnListOfMyTableCell,
            prefix,
          })
        }
      }
      if (item.list && item.list.length > 0) {
        for (let i = 0; i < item.list.length; i++) {
          let _itemList = item.list[i]
          FlowToggleExpandItem({
            configItemExpand: _itemList,
            row,
            prefix,
            //an fnList di vi ko muon goi forceupdate ben trong
            // fnListOfMyTableCell,
            // forceUpdateCell,
          })
        }
      }
      if (forceUpdateCell) {
        forceUpdateCell()
      }
      console.warn("FlowToggleExpandItem:", fnListOfMyTableCell)
      fnListMyPage.fnForceUpdateTable(fnListOfMyTableCell, []) //force update table tu fnListOfMyTableCell
    }
  }
}
