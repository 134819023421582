import MyPageOne from "./MyPage"
import { HUtils, HConstant } from "src/core/helpers"
interface IModuleUserSetting extends IModuleOne {
  runAfterInit: (HConfig: any) => void
}
const ModuleUserSetting: IModuleUserSetting = {
  runAfterInit: HConfig => {
    MyPageOne.init()
    HUtils.runFuntion(HConfig, HConstant.HConfig.FnList.addPrivateRoutes, [
      [
        {
          Id: "setting_main",
          UIUrl: "/setting",
          UIType: "setting_main",
        },
      ],
    ])
  },
}

export default ModuleUserSetting
