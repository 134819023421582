import { MyLoading } from "@macashipo/mlib"
import React from "react"
import { useHistory } from "react-router-dom"
import { PATH } from "src/helpers/paths"
import { LoginContainer, Logo, LogoContainer } from "./Login.styles"
const Container = props => {
  const history = useHistory()
  return (
    <LoginContainer className="container">
      <div className="min-vh-100 row">
        <div className="col-md-8 col-lg-6 m-auto position-relative">
          <LogoContainer
            onClick={() => {
              history.push(PATH.HOME)
            }}
          >
            <Logo src={"/config/logo.png"} />
          </LogoContainer>
          {props.children}
          {props.loading === true && (
            <div
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <MyLoading />
            </div>
          )}
        </div>
      </div>
    </LoginContainer>
  )
}

export default Container
