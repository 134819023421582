import React from "react"
import { MyUIHelpers } from "@macashipo/mlib"
import MUIGrid from "./Types/grid"
const MUIATHeader = React.lazy(() => import("./Types/admintable_header"))
const MUIATChart = React.lazy(() => import("./Types/admintable_chart"))
const MUIATHeaderDetail = React.lazy(() =>
  import("./Types/admintable_header_detail")
)
const MUIATHeaderMini = React.lazy(() =>
  import("./Types/admintable_header_mini")
)
const MUIATHeaderButtons = React.lazy(() =>
  import("./Types/admintable_header_buttons")
)
const MUIATFilter = React.lazy(() => import("./Types/admintable_filter"))
const MUIATFilterDetail = React.lazy(() =>
  import("./Types/admintable_filter_detail")
)
const MUIATTable = React.lazy(() => import("./Types/admintable_table"))
const MUIATTableDetails = React.lazy(() =>
  import("./Types/admintable_table").then(module => ({
    default: module.MUIATTableDetails,
  }))
)
const MUIATTableList = React.lazy(() => import("./Types/admintable_table_list"))
const MUIATTableListCard = React.lazy(() =>
  import("./Types/admintable_table_list_card")
)
const MUIATTableGridCard = React.lazy(() =>
  import("./Types/admintable_table_grid_card")
)
const MUISelectBox = React.lazy(() => import("./Types/select_box"))
const MUISelectBoxSearch = React.lazy(() => import("./Types/select_box_search"))
const MUISelectBoxSearchV2 = React.lazy(() =>
  import("./Types/select_box_search_v2")
)
const MUISelectCheckBoxSearch = React.lazy(() =>
  import("./Types/selectcheck_box_search")
)
const MUIMsgBoxConfirm = React.lazy(() => import("./Types/msgbox_confirm"))
const MUIMsgBoxError = React.lazy(() => import("./Types/msgbox_error"))
const MUIAnimate = React.lazy(() => import("./Types/animate"))
const MUIFileManager = React.lazy(() => import("./Types/file_manager"))
const MUIFileManagerItem = React.lazy(() => import("./Types/file_manager_item"))
const MUIArrTags = React.lazy(() => import("./Types/arr_tags"))
const MUIActionMenu = React.lazy(() => import("./Types/actions_menu"))
const MUILayoutSquare = React.lazy(() => import("./Types/layout_square"))
const MUISearchBox = React.lazy(() => import("./Types/search_box"))
const MUIATFilterPopover = React.lazy(() =>
  import("./Types/admintable_filter_popover")
)
const MUIWrapCellOnModal = React.lazy(() => import("./Types/_wrapCellOnModal"))
const MUIWrapEditRowInCard = React.lazy(() =>
  import("./Types/_wrapEditRowInCard")
)
const MUI_avatar = React.lazy(() => import("./Types/avatar"))
const MUI_Api_Client = React.lazy(() => import("./Types/api_client"))
const MUI_Select_Box_Search_Virtualized = React.lazy(() =>
  import("./Types/select_box_search_virtualized")
)
const List = {
  _wrapEditRowInCard: MUIWrapEditRowInCard,
  _wrapCellOnModal: MUIWrapCellOnModal,
  avatar: MUI_avatar,
  admintable_filter_popover: MUIATFilterPopover,
  admintable_chart: MUIATChart,
  search_box: MUISearchBox,
  layout_square: MUILayoutSquare,
  actions_menu: MUIActionMenu,
  arr_tags: MUIArrTags,
  selectcheck_box_search: MUISelectCheckBoxSearch,
  admintable_header: MUIATHeader,
  admintable_header_detail: MUIATHeaderDetail,
  admintable_header_mini: MUIATHeaderMini,
  admintable_header_buttons: MUIATHeaderButtons,
  admintable_filter: MUIATFilter,
  admintable_filter_detail: MUIATFilterDetail,
  admintable_table: MUIATTable,
  admintable_table_details: MUIATTableDetails,
  admintable_table_list: MUIATTableList,
  admintable_table_list_card: MUIATTableListCard,
  admintable_table_grid_card: MUIATTableGridCard,
  select_box: MUISelectBox,
  select_box_search: MUISelectBoxSearch,
  select_box_search_v2: MUISelectBoxSearchV2,
  msgbox_confirm: MUIMsgBoxConfirm,
  msgbox_error: MUIMsgBoxError,
  animate: MUIAnimate,
  file_manager: MUIFileManager,
  file_manager_item: MUIFileManagerItem,
  api_client: MUI_Api_Client,
  select_box_search_virtualized: MUI_Select_Box_Search_Virtualized,
}

const ListNoLazy = {
  grid: MUIGrid,
}
const MyUIOne = {
  init() {
    MyUIHelpers.addTypesFromListLazy(List)
    MyUIHelpers.addTypesFromListWithoutLazy(ListNoLazy)
  },
}

export default MyUIOne
