export const KConfigPageTable = {
  addExtraColumns: "addExtraColumns", //extra columns
  showDetails: "showDetails", //co expand ko
  showDetailsMulti: "showDetailsMulti", //co expand ko
  hideColDelete: "hideColDelete", //an cot delete
  hideColDeleteOnSmallWidth: "hideColDeleteOnSmallWidth", //an cot delete neu width nho ko
  customDelete: "customDelete", //custom delete column
  canDelete: "canDelete", //cho phep delete ko
  showHeader: "showHeader", //co show header table ko
  show: "show", //co show table ko
  hasCustomHeader: "hasCustomHeader", //co custom header ko, thay doi class table
  hasWrapHeader: "hasWrapHeader", //co wrap header ko, thay doi class table
  noBorder: "noBorder",
  noBorderCol: "noBorderCol",
  borderLight: "borderLight",
  hasBigBorderBottom: "hasBigBorderBottom",
  classNameTable: "classNameTable", //bo sung class name cho table
  forceFullExpandDetail: "forceFullExpandDetail",
  showLoadingWhenFilter: "showLoadingWhenFilter",
  showID: "showID", //show col ID
  colsActions: "colsActions", //cols actions
  customProps: "customProps", //custom props table build cols
  needRebuildColAfterGetList: "needRebuildColAfterGetList",
  hasExpand: "hasExpand",
  sizePerPage: "sizePerPage",
  hideSizePerPage: "hideSizePerPage",
  optionsTable: "optionsTable", //options cho table
  hasPagingRemote: "hasPagingRemote", // paging server
  tableProps: "tableProps", //bo sung props cho tabled
  deleteTitle: "deleteTitle",
  deleteContent: "deleteContent",
  deleteContentHtml: "deleteContentHtml",
  deleteWithNote: "deleteWithNote",
  fDelete: "fDelete", //dung o delete
  styleHeader: "styleHeader", //style chung cho header neu custom style header
  belowTable: "belowTable", //config MyUI show below table array item: type, groupByFn, groupByFnArgs
  needReloadAfterUpdateFields: "needReloadAfterUpdateFields", //array field khi goi update field, se goi reload list
}

export const KConfigPageTableDefault = {
  showHeader: true,
  showID: false,
}

export const KConfigPageFilter = {
  type: "type",
  customQueryFromSever: "customQueryFromSever",
  position: "position",
  searchServer: "searchServer",
  showSearch: "showSearch",
  needCheckLastItemQueryServer: "needCheckLastItemQueryServer", //kiem tra last item filter co isServer ko de quyet dinh co requestList hay ko, mac dinh la false
}

export const KConfigPageDetail = {
  multi: "multi",
  needReloadMaster: "needReloadMaster",
}

export const KConfigPageHeader = {
  show: "show",
  title: "title", //custom title on header
  hideTitle: "hideTitle", //hide title
  showAddNew: "showAddNew",
  showProjectHeader: "showProjectHeader", //show project header for solid
  preButtons: "preButtons", //bo sung button tu ScreenConfig, them phia truoc
  sufButtons: "sufButtons", //bo sung button tu ScreenConfig, them phia sau
}

export const KConfigPagePage = {
  topTable: "topTable",
  topHeader: "topHeader",
  belowHeader: "belowHeader",
  belowFilter: "belowFilter",
  configCard: "configCard", //dung de config card, dung cho cac page su dung card
}

export const KConfigPageExpander = {
  defaultExpand: "defaultExpand",
  hasPageExpand: "hasPageExpand",
}

export const KEventsApp = {
  ee_sidemenu_forceupdate: "ee_sidemenu_forceupdate",
}

export const KConfigApp = {
  baseUrl: "baseUrl",
  defaultTypeHubFilter: "defaultTypeHubFilter",
  defaultPositionFilter: "defaultPositionFilter",
  GoogleClientId: "GoogleClientId",
  hasRegisterAccount: "hasRegisterAccount",
  hasForgotPassword: "hasForgotPassword",
  hasLoginSocialFacebook: "hasLoginSocialFacebook",
  hasLoginSocialGoogle: "hasLoginSocialGoogle",
  widthMobile: "widthMobile",
  isDevMode: "isDevMode", //from v5
  home_url: "home_url",
  DefaultHomeURL: "DefaultHomeURL",
  AuthInfo: "AuthInfo",
  appLogo_url: "appLogo_url",
  verAdmin: "verAdmin", //version admin, v1,v3,v5
  defaultTagInputCell: "defaultTagInputCell", //default tag, input or textarea for cell using input
  hasSetting2FA: "hasSetting2FA", //co setting 2FA ko
  showClassFieldOnCell: "showClassFieldOnCell",
  title: "title",
  serviceWorkersRun: "serviceWorkersRun",
  hasSignalR: "hasSignalR", //for signalR
  signalRHub: "signalRHub", //signalR hub link
  signalRKey: "signalRKey", //signalR key
  hasPushNotify: "hasPushNotify",
  fileUploadSupport: "fileUploadSupport",
  numberLocale: "numberLocale",
  expandIconHideColor: "expandIconHideColor", //
  autoRedirectLoginPageNotFound: "autoRedirectLoginPageNotFound", //auto redirect login when show page not found
  mtf_datefromto_showRange: "mtf_datefromto_showRange", //default showRange filter datefromto
}

export const KConfigModal = {
  key: "key", //
  title: "title", //
  fTitle: "fTitle", //use for cell, get title from row
  className: "className",
  showFooter: "showFooter",
  showHeader: "showHeader",
  type: "type", //type of modal
  typeMyPage: "typeMyPage", //type mypage if type is mypage
  backdrop: "backdrop", //static or false
  size: "size", //md lg xl
}

export const KUI = {
  fnshowDialog: "fnshowDialog",
  fnShowToast: "fnShowToast",
  fnshowConfirm: "fnshowConfirm",
  fnShowLoadingScreen: "fnShowLoadingScreen",
  fnForceUpdateRoutes: "fnForceUpdateRoutes",
}

export const KConstant = {
  _NullOrEmpty_: "_NullOrEmpty_",
}

export const KApiName = {
  List: "List",
  Options: "Options",
  UpdateFields: "UpdateFields",
}

//when using json on table header
export const KConfigTableHeader = {
  type: "type",
  header: "header",
  style: "style",
  title: "title",
  tooltip: "tooltip",
  html: "html",
}

export const KMyUITypeCommon = {
  html: "html",
}

export const KStorage = {
  _localConfig: "_localConfig",
  _localBaseUrl: "_localBaseUrl",
  _usingLocalBaseUrl: "_usingLocalBaseUrl",
  redirectAfterLogin: "redirectAfterLogin",
  HomeURL: "HomeURL",
  AuthInfo: "Auth-Info",
}

export const KClassBody = {
  appFullContainer: "app-full-container", //remove padding container of page
}

const K = {
  fnGetListExtraData: "fnGetListExtraData",
  fnGetConfigFilter: "fnGetConfigFilter",
  fnGetOptions: "fnGetOptions",
  KConfigApp,
  KConfigPageTable,
  KConfigPageTableDefault,
  KConfigPageFilter,
  KUI,
  KConstant,
  KApiName,
  KMyUITypeCommon,
}
export default K
