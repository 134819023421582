import MyModal from "./MyModal"
const MyDialog = {
  show({ title = "", msg = "", onClickOK = (cModal: any) => {} } = {}) {
    MyModal.showMyDialog({
      title: title,
      component: msg,
      modalCentered: true,
      size: "md",
      showFooter: true,
      showFooterOK: true,
      backdrop: "static",
      onClickOK: cModal => {
        if (onClickOK) {
          onClickOK(cModal)
        }
        if (cModal.hide) {
          cModal.hide()
        }
      },
    })
  },
  showConfirm({ title = "", msg = "", onClickOK = (cModal: any) => {} } = {}) {
    MyModal.showMyDialog({
      title: title,
      component: msg,
      modalCentered: true,
      size: "md",
      showFooter: true,
      showFooterOK: true,
      showFooterClose: true,
      backdrop: "static",
      onClickOK: cModal => {
        if (onClickOK) {
          onClickOK(cModal)
        }
        if (cModal.hide) {
          cModal.hide()
        }
      },
    })
  },
}

export default MyDialog
