import React from "react"
import { MyLibHelpers } from "@macashipo/mlib"

const MLSelect2 = React.lazy(() => import("./Types/select2"))
const MLMonacoEditor = React.lazy(() => import("./Types/monacoeditor"))
const ML_chromepicker = React.lazy(() => import("./Types/chromepicker"))
const ML_code_mirror = React.lazy(() => import("./Types/code_mirror"))
const ML_diff_viewer = React.lazy(() => import("./Types/diff_viewer"))
const ML_draft_wysiwyg = React.lazy(() => import("./Types/draft_wysiwyg"))
const List = {
  draft_wysiwyg: ML_draft_wysiwyg,
  diff_viewer: ML_diff_viewer,
  code_mirror: ML_code_mirror,
  chromepicker: ML_chromepicker,
  select2: MLSelect2,
  monacoeditor: MLMonacoEditor,
}
const MyLibOne = {
  init() {
    MyLibHelpers.addTypesFromListLazy(List)
  },
}

export default MyLibOne
