import { HIs, HJson } from "@macashipo/mlib"
export const KMoreOfForm = {
  typeWrapControl: "typeWrapControl",
  btnSubmit: "btnSubmit",
  btnReset: "btnReset",
}

export const FlowGetConfigFormFromConfigServer = (
  configV1,
  { defaultValues, fnList, moreDefaultValues }
) => {
  if (
    configV1 === null ||
    configV1 === undefined ||
    (configV1 && Object.keys(configV1).length === 0)
  ) {
    return null
  }
  let _controls = []
  let _moreOfForm = configV1.More || {}
  if (configV1.More && HIs.isString(configV1.More)) {
    _moreOfForm = HJson.getObj(configV1.More)
  }
  let _defaultValues = {
    ...defaultValues,
    ...configV1.DefaultValues,
    ...moreDefaultValues,
  }
  // console.warn("defaultValues:", _defaultValues)
  if (configV1.Data && configV1.Data.length > 0) {
    _controls = configV1.Data.map((v, i) => {
      let _more = {}
      if (v.Props && HIs.isString(v.Props)) {
        _more = HJson.getObj(v.Props)
      }
      return {
        label: v.Label,
        fieldName: v.FieldName,
        type: v.Type,
        more: {
          ..._more,
          hint: v.Hint,
          disabled: v.Disabled || false,
          optionKey: v.Source || v.SourceField,
        },
      }
    })
  }
  return {
    title: configV1.Title,
    closeMyModalAfterSubmit: true,
    defaultValues: _defaultValues,
    controls: _controls,
    fnList: fnList,
    more: {
      ..._moreOfForm,
    },
  }
}
