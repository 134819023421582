import React from "react"
import { MyPageHelpers } from "@macashipo/mlib"

const MyPageWelcome = React.lazy(() => import("./Types/welcome"))
const MyPageAdminTab = React.lazy(() => import("./Types/admin_tab"))
const MyPageAdminTable = React.lazy(() => import("./Types/admin_table"))
const MyPageAdminTableClient = React.lazy(() =>
  import("./Types/admin_table_client")
)
const MyPageAdminPageNotFound = React.lazy(() =>
  import("./Types/admin_pagenotfound")
)
const MyPageWithMyUI = React.lazy(() => import("./Types/with_myui"))
const MyPageAdminDashboard = React.lazy(() => import("./Types/admin_dashboard"))
const MyPageAdminList = React.lazy(() => import("./Types/admin_table_list"))
const MyPageAdminListCard = React.lazy(() =>
  import("./Types/admin_table_list_card")
)
const MyPageAdminGridCard = React.lazy(() =>
  import("./Types/admin_table_grid_card")
)
const MP_API_Document = React.lazy(() => import("./Types/api_document"))
const MyPageMyProfile = React.lazy(() => import("./Types/myprofile"))
const MyPageApiRequest = React.lazy(() => import("./Types/api_request"))
const List = {
  admin_tab: MyPageAdminTab,
  admin_table: MyPageAdminTable,
  admin_pagenotfound: MyPageAdminPageNotFound,
  admin_table_client: MyPageAdminTableClient,
  admin_dashboard: MyPageAdminDashboard,
  admin_table_list: MyPageAdminList,
  admin_table_list_card: MyPageAdminListCard,
  admin_table_grid_card: MyPageAdminGridCard,
  welcome: MyPageWelcome,
  with_myui: MyPageWithMyUI,
  //default type
  table: MyPageAdminTable,
  tab: MyPageAdminTab,
  table_list: MyPageAdminList,
  table_list_card: MyPageAdminListCard,
  table_grid_card: MyPageAdminGridCard,
  myprofile: MyPageMyProfile,
  // api_document: MP_API_Document,
  api_request: MyPageApiRequest,
}
const MyPageOne = {
  init() {
    MyPageHelpers.addTypesFromListLazy(List)
  },
}

export default MyPageOne
